import { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import {
  padZeros,
  dateFormat,
  timeFormat,
  numberFormat,
} from "../../../Functions/functions";
import { LuCalendarClock, LuMail, LuMap, LuMapPin, LuPhone, LuUser, LuUser2 } from "react-icons/lu";
import axios from "axios";
import { variants as variantURL } from "../../../Data/apiRoutes";
import { useSelector } from "react-redux";
import RetryPaymentModal from "./RetryPaymentModal";

export default ({ order, fetchOrder }) => {
  const token = useSelector((state) => state.token);
  const [orderList, setOrderList] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [complete, setComplete] = useState(false);
  const [paid, setPaid] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false)

  const fetchVariant = async (id) => {
    try {
      const response = await axios.get(`${variantURL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      console.log(order);
      setOrderList([]);
      await order?.OrderItems?.forEach(async (element) => {
        const item = element;
        if (item?.description) {
          const id = item.description.split("|")[0];
          item.variant = await fetchVariant(id);
        }
        console.log(item);
        setOrderList((state) => [...state, item]);
        setTotalDiscount((state) => state + item.discount);
      });
    })();
  }, [order]);

  return (
    <Container transparent>
      <RetryPaymentModal order={order} token={token} show={showRetryModal} close={() => setShowRetryModal(s => !s)} fetchOrder={fetchOrder} />

      <div className="md:flex items-center gap-16 mb-6">
        <span className="font-bold">Order #MO-{padZeros(order?.id, 7)}</span>
        <div className="flex gap-6 justify-between">
          <div>
            {paid ? <span className="bg-green-600 p-1 px-3 text-xs rounded-full me-2">
              Paid
            </span> : <span className="bg-yellow-600 p-1 px-3 text-xs rounded-full me-2">
              Not Paid
            </span>}
            <span className="bg-yellow-600 p-1 px-3 text-xs rounded-full">
              Not Fulfilled
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <LuCalendarClock />
            {dateFormat(order?.createdAt)}
            {" - "}
            {timeFormat(order?.createdAt)}
          </div>
        </div>
      </div>

      {/* ORDER ITEMS */}
      <Container ypadding="" xpadding="" className="flex flex-col gap-2">
        {orderList?.length > 0 &&
          orderList?.map((item) => (
            <div className="flex gap-4 border-b pb-2">
              <div className="w-[130px] h-[130px]">
                <img
                  src={
                    item?.variant
                      ? `${
                          process.env.REACT_APP_BACKEND_URL
                        }/${item?.variant?.picture
                          ?.replace('"', "")
                          .replace('"', "")}`
                      : item?.Product?.picture
                      ? `${
                          process.env.REACT_APP_BACKEND_URL
                        }/${item?.Product?.picture
                          ?.replace('"', "")
                          .replace('"', "")}`
                      : "/img/shop-icon.png"
                  }
                  alt="product"
                  className="object-cover w-[130px] h-[130px]"
                />
              </div>

              <div className="flex flex-col md:gap-4 flex-1">
                <div className="md:grid grid-cols-6 items-center">
                  <div className="font-bold col-span-3 text-sm md:text-base">
                    {item?.Product?.name}
                  </div>
                  <div className="text-xs md:text-base">
                    <span className="text-gray-600 dark:text-gray-400 md:hidden">
                      Unit Price:{" "}
                    </span>
                    K{numberFormat(item?.unit_price)}
                  </div>
                  <div className="text-xs md:text-base">
                    <span className="text-gray-600 dark:text-gray-400 md:hidden">
                      QTY:{" "}
                    </span>
                    {item?.quantity}
                  </div>
                  <div className="text-xs md:text-base">
                    <span className="text-gray-600 dark:text-gray-400 md:hidden">
                      Total:{" "}
                    </span>
                    K{numberFormat(item?.quantity * item?.unit_price)}
                  </div>
                </div>
                <div className="flex flex-col md:gap-1 text-xs md:text-sm">
                  {item?.variant?.color && (
                    <div>
                      <span className="text-gray-600 dark:text-gray-400">
                        Color:
                      </span>{" "}
                      {item?.variant?.color}
                    </div>
                  )}
                  {item?.variant?.size && (
                    <div>
                      <span className="text-gray-600 dark:text-gray-400">
                        Size:
                      </span>{" "}
                      {item?.variant?.size}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </Container>
      {/* END ORDER ITEMS */}

      <div className="md:grid grid-cols-5 gap-2">
        {/* PAYMENT SUMMARY */}
        <Container ypadding="my-4" xpadding="" outterClassName="col-span-3">
          <div className="flex justify-between items-center mb-2">
            <div className="font-bold mb-2">Payments Summary</div>
            {!paid && <PrimaryButton onClick={() => setShowRetryModal(s => !s)}>Retry Payment</PrimaryButton>}
          </div>
          <div className="flex justify-between">
            <div>Subtotal: </div>
            <div>K{numberFormat(order?.subtotal)}</div>
          </div>
          <div className="flex justify-between">
            <div>Discount: </div>
            <div>K{numberFormat(totalDiscount)}</div>
          </div>

          <div className="font-medium mt-2">Payments made by customer</div>
          {order?.Payments?.length > 0 &&
            order?.Payments?.map((payment) => {
              if (payment.status === "completed" && !paid) {
                setPaid(true);
              }
              return (
                <div className="grid grid-cols-5 border-b p-1">
                  <div>{payment.trans_id}</div>
                  <div
                    className={`${
                      payment.status === "pending"
                        ? "text-yellow-500"
                        : "text-green-500"
                    }`}
                  >
                    {payment.status === "pending"
                      ? "Unsuccessful"
                      : "Successful"}
                  </div>
                  <div className="capitalize">{payment.method}</div>
                  <div>{dateFormat(payment.createdAt)}</div>
                  <div className="text-right">
                    K{numberFormat(payment.amount)}
                  </div>
                </div>
              );
            })}
        </Container>
        {/* END PAYMENT SUMMARY */}
        {/* PAYMENT SUMMARY */}
        <Container ypadding="my-4" xpadding="" outterClassName="col-span-2">
          <div className="font-bold mb-2">Customer Details</div>

          <div className="flex gap-4 items-center my-2">
            <img
              src={
                order?.User?.picture
                  ? `${process.env.REACT_APP_BACKEND_URL}/${order?.User?.picture
                      ?.replace('"', "")
                      .replace('"', "")}`
                  : "/img/shop-icon.png"
              }
              alt="product"
              className="object-cover w-[36px] h-[36px] rounded-full border"
            />
            {order?.User?.name}
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuMail />
              <div>{order?.User?.email}</div>
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuPhone />
              <div>{order?.User?.phone_number}</div>
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuMap />
              <div>{order?.User?.location}, {order?.User?.district}</div>
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuMapPin />
              <div>{order?.User?.postal_address}</div>
          </div>

          <div className="font-bold mt-4 mb-2">Receipient Info</div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuUser2 />
              <div>{order?.recipient_name}</div>
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuMail />
              <div>{order?.recipient_email}</div>
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuPhone />
              <div>{order?.recipient_number}</div>
          </div>
          <div className="flex gap-5 ms-3 items-center my-2">
              <LuMap />
              <div>{order?.recipient_location}</div>
          </div>
        </Container>
        {/* END PAYMENT SUMMARY */}
      </div>
    </Container>
  );
};
