export default function PrimaryButton({
  className = "",
  disabled,
  children,
  ...props
}) {
  return (
    <button
      {...props}
      className={
        `inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs bg-red-700 dark:bg-red-600 text-white dark:text-gray-200 uppercase tracking-widest hover:bg-red-800 dark:hover:bg-red-700 focus:bg-red-600 dark:focus:bg-red-700 active:bg-red-800 dark:active:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:focus:ring-offset-red-800 transition ease-in-out duration-150 ${
          disabled && "opacity-25"
        } ` + className
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
}
