import MainLayout from "../../Layouts/MainLayout";
import { useSelector } from "react-redux";
import CustomerHome from "./Customer";
import AdminHome from "./Admin";
import SellerHome from "./Seller";

export default function Home() {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  return (
    <MainLayout>
      {user?.role === "admin" ? (
        <AdminHome />
      ) : user?.role === "seller" ? (
        <SellerHome user={user} token={token} />
      ) : (
        <CustomerHome />
      )}
    </MainLayout>
  );
}
