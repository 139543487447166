import { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import { useSelector, useDispatch } from "react-redux";
import { setShops } from "../../../Context/reducer";
import { shops as shopURL } from "../../../Data/apiRoutes";
import { Link } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const shops = useSelector((state) => state.shops);
  const fetchShops = async () => {
    const response = await fetch(shopURL);
    const data = await response.json();
    console.log(data.shops);
    dispatch(setShops(data.shops));
  };

  useEffect(() => {
    fetchShops();
  }, []);

  return (
    <Container transparent xpadding="" padding="">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {!!shops && shops.length > 0 ? (
          shops.map((shop) => (
            <Container key={shop.id} ypadding="" xpadding="">
              <div className="flex gap-4">
                <Link to={`/shops/${shop.id}`} className="h-[100px] w-[128px]">
                  <img
                    src={
                      shop?.picture
                        ? `${
                            process.env.REACT_APP_BACKEND_URL
                          }/${shop?.picture?.replace(/"/g, "")}`
                        : "/img/image_icon1.png"
                    }
                    className="h-[100px] w-[128px] object-cover shadow-sm rounded"
                  />
                </Link>
                <div className="flex flex-col w-full">
                  <Link
                    to={`/shops/${shop.id}`}
                    className="font-bold text-lg hover:underline"
                  >
                    {shop.name}
                  </Link>
                  <div className="text-gray-700 dark:text-gray-300 text-sm text-justify">
                    {shop.description.length > 50
                      ? shop.description.substr(0, 50) + "..."
                      : shop.description}
                  </div>
                  <div className="mt-auto flex justify-between items-center">
                    <Link
                      to={`/profile/${shop?.User?.id}`}
                      className="text-xs underline text-gray-600 dark:text-gray-400"
                    >
                      {shop?.User?.name}
                    </Link>
                    <Link to={`/shops/${shop.id}`}>
                      <PrimaryButton>View</PrimaryButton>
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          ))
        ) : (
          <div className="md:col-span-2 lg:col-span-3">
            <Container className="flex h-[50vh] justify-center items-center">
              <div className="text-xl font-bold">
                There are no shops at the moment.
              </div>
            </Container>
          </div>
        )}
      </div>
    </Container>
  );
};
