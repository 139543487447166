import { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setIsMessenger, removeRecipient } from "../Context/reducer";
import TimeAgo from "react-timeago";
import io from "socket.io-client";
import { chatSocketUrl as socketUrl } from "../Data/apiRoutes";
import Container from "../Components/Container";
import { dateFormat, timeFormat } from "../Functions/functions";

export default () => {
  const [socket, setSocket] = useState(null);
  const [convoID, setConvoID] = useState("");
  const [outMessage, setOutMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const chatboxRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user);
  const recipient = useSelector((s) => s.recipient);

  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (socket && outMessage) {
      const data = {
        conversation_id: convoID,
        user_id: user.id,
        actual_msg: outMessage,
        status: "unseen",
        type_of_msg: "text",
      };
      socket.emit("message sent", data);
      setOutMessage("");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const socketInstance = io(`${socketUrl}`);
    setSocket(socketInstance);

    socketInstance.on("connect", () => {
      socketInstance.emit("getChatId", { user1: user, user2: recipient });

      console.log("Connected to server");
    });

    socketInstance.on("chatIdResponse", (data) => {
      setConvoID(data);
      socketInstance.emit("user chat", data);
    });

    socketInstance.on("message all", (data) => {
      setMessages(data);
    });

    socketInstance.on("message received", (data) => {
      setMessages((s) => [...s, data]);
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [recipient]);
  return (
    <Container padding="">
      <div className="flex gap-4 rounded shadow-sm p-2 items-center">
        <img
          src="/img/user-icon.png"
          className="h-10 w-10 object-cover rounded-full"
        />
        <div className="w-full">
          <div className="text-xl font-bold">{recipient.name}</div>
        </div>
      </div>
      <div
        id="chatbox"
        className="p-4 h-[66vh] overflow-y-auto flex flex-col"
        ref={chatboxRef}
      >
        {!!messages &&
          messages.length > 0 &&
          messages.map((message) => {
            return (
              <>
                {message.user_id !== user.id ? (
                  <div className="mb-2 me-10">
                    <p className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-50 rounded-2xl rounded-bl-none py-2 px-4 inline-block">
                      {message.actual_msg}
                      <div className="text-gray-500 dark:text-gray-400 text-xs mt-2">
                        {/* <TimeAgo date={message.createdAt} /> */}
                        {dateFormat(new Date()) !==
                        dateFormat(message.createdAt)
                          ? dateFormat(message.createdAt)
                          : ""}{" "}
                        {timeFormat(message.createdAt)}
                      </div>
                    </p>
                  </div>
                ) : (
                  <div className="mb-2 ms-10 self-end">
                    <p className="bg-blue-500 dark:bg-blue-900 text-white rounded-2xl rounded-br-none py-2 px-4 inline-block">
                      {message.actual_msg}
                      <div className="text-gray-300 dark:text-gray-400 text-right text-xs mt-2">
                        {/* <TimeAgo date={message.createdAt} /> */}
                        {dateFormat(new Date()) !==
                        dateFormat(message.createdAt)
                          ? dateFormat(message.createdAt)
                          : ""}{" "}
                        {timeFormat(message.createdAt)}
                      </div>
                    </p>
                  </div>
                )}
              </>
            );
          })}
      </div>
      <div className="p-4 border-t dark:bg-gray-800 flex rounded-b-lg gap-2">
        <input
          value={outMessage}
          onChange={(e) => setOutMessage(e.target.value)}
          type="text"
          placeholder="Type a message"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-900 dark:text-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-900"
        />
        <button
          onClick={handleSubmit}
          className="bg-blue-500 dark:bg-blue-900 text-white hover:bg-blue-600 dark:hover:bg-blue-950 p-2 w-12 flex items-center justify-center rounded-full transition duration-300"
        >
          <IoSend />
        </button>
      </div>
    </Container>
  );
};
