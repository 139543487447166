import Container from "../../../Components/Container";
import { FaMoneyBill, FaArrowUp, FaPlus } from "react-icons/fa";
import KPIBox from "../../../Components/KPIBox";
import LineChart from "../../../Components/LineChart";
import PieCharts from "../../../Components/PieCharts";
import BarChart from "../../../Components/BarChart";
import { useOrderStats } from "../../../Hooks/useStats";
import { stats as statsRoute } from "../../../Data/apiRoutes";
import { useEffect, useState } from "react";
import axios from "axios";
import { numberFormat } from "../../../Functions/functions";

export default function SellerHome({ user, token }) {
  const [totalSales, setTotalSales] = useState(0);
  const [categoriesData, setCategoriesData] = useState([]);
  const [shopsData, setShopsData] = useState([]);

  const getTotalSales = async () => {
    try {
      const response = await axios.get(`${statsRoute}/payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalSales(response.data[0].order_amount)
    } catch (error) {
      console.log(error)
    }
  };

  const getCatogoryStat = async () => {
    try {
      const response = await axios.get(`${statsRoute}/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        data.push({
          name: element.name,
          value: parseInt(element.total_purchases)
        })
      }
      setCategoriesData(data)
    } catch (error) {
      console.log(error)
    }
  };

  const getShopStat = async () => {
    try {
      const response = await axios.get(`${statsRoute}/shops`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        data.push({
          name: element.name,
          Products: parseInt(element.product_count),
          Orders: parseInt(element.order_item_count),
        })
      }
      console.log(data)
      setShopsData(data)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    getTotalSales();
    getCatogoryStat();
    getShopStat();
  }, [])
  return (
    <Container ypadding="py-2 sm:py-6" transparent>
      <div className="grid lg:grid-cols-2 gap-6">
        <KPIBox
          title="Total Sales"
          content={`K ${numberFormat(totalSales)}`}
          percentage="18"
          increment="18.5K this week"
          status={"normal"} //"more", "less", "normal"
        />

        <KPIBox
          title="Account Balance"
          content="K 1,000,000.00"
          percentage="18"
          increment="18.5K this week"
          status={"normal"} //"more", "less", "normal"
        />
      </div>
      <div className="w-full h-[400px] bg-gray-200 dark:bg-gray-800 rounded mt-4 pb-16">
        <div className="font-bold p-4">Sales Trends</div>
        <LineChart />
      </div>

      <div className="lg:grid lg:grid-cols-5 gap-4">
        <div className="w-full h-[400px] bg-gray-200 dark:bg-gray-800 rounded mt-4 pb-16 col-span-3">
          <div className="font-bold p-4">Shop Performance</div>
          <BarChart data={shopsData} />
        </div>
        <div className="w-full h-[400px] bg-gray-200 dark:bg-gray-800 rounded mt-4 pb-16 col-span-2">
          <div className="font-bold p-4">Category Performance</div>
          <PieCharts  data={categoriesData}/>
        </div>
      </div>
    </Container>
  );
}
