import Container from "../../../Components/Container";
import { useQuery } from "../../../Hooks/useQuery";
import MainLayout from "../../../Layouts/MainLayout";
import { useParams } from 'react-router-dom';
import { BiError } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import OrderSuccess from "../../OrderSuccess";
import { useEffect, useState } from "react";
import {payments as paymentUrl} from "../../../Data/apiRoutes"
import axios from "axios"

export default function StatusOrder() {
    const token = useSelector(state => state.token)
    const {order, order_items, payment, email, first_name, last_name} = useSelector(state => state.tempData);
    let { method } = useParams();
    let query = useQuery();
    let [status] = useState(query.get('status'));
    let [tx_ref] = useState(query.get('tx_ref'));

    const confirmPayment = async () => {
        try {
            const response = await axios.post(`${paymentUrl}/confirm`, {
                tx_ref: payment?.trans_id,
                order_id: order?.id

            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            console.log(response.data)
        } catch(e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if(status !== 'failed' && tx_ref === payment.trans_id) {
            confirmPayment();
        }
    }, [status, tx_ref]);

    return <MainLayout>
        <OrderSuccess
          order={order}
          orderItems={order_items}
          payment={payment}
          email={email}
          status={status || 'success'}
          first_name={first_name}
          last_name={last_name}
        />
    </MainLayout>
}