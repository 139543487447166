import AppLogo from "../Components/AppLogo";
import { useEffect, useState } from "react";
import NavLink from "../Components/NavLink";
import ResponsiveNavLink from "../Components/ResponsiveNavLink";
import ResponsiveNavButton from "../Components/ResponsiveNavButton";
import {
  admin as adminRoutes,
  guest as guestRoutes,
  customer as navRoutes,
  seller as sellerRoutes,
} from "../Data/navlinks";
import Dropdown from "../Components/DropDown";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { setLogout } from "../Context/reducer";

export default function NavBar() {
  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const signOut = () => {
    navigate("/");
    dispatch(setLogout());
    setShowingNavigationDropdown(false);
  };

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  return (
    <nav
      className={`${
        colorChange ? "bg-white dark:bg-gray-800" : "bg-white dark:bg-gray-800"
      }  border-b border-gray-100 dark:border-gray-700 ${
        currentRoute.startsWith("/messages")
          ? ""
          : "fixed z-50 right-0 left-0 top-0"
      }`}
    >
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="shrink-0 flex items-center">
              <Link
                to={"/"}
                className="shrink-0 flex items-center dark:text-white"
              >
                <AppLogo className="block h-9 w-auto fill-gray-800 dark:fill-gray-200" />
                <div className="text-gray-800 dark:text-gray-200 font-extrabold text-xl">
                  Malonda265
                </div>
              </Link>
            </div>
            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
              {user?.email_verified_at === null
                ? [].map((route) => (
                    <NavLink
                      key={route.href}
                      active={route.href === currentRoute}
                      to={route.href}
                    >
                      {route.text}
                    </NavLink>
                  ))
                : user?.role === "admin"
                ? adminRoutes.map((route) => (
                    <NavLink
                      key={route.href}
                      active={route.href === currentRoute}
                      to={route.href}
                    >
                      {route.text}
                    </NavLink>
                  ))
                : user?.role === "seller"
                ? sellerRoutes.map((route) => (
                    <NavLink
                      key={route.href}
                      active={route.href === currentRoute}
                      to={route.href}
                    >
                      {route.text}
                    </NavLink>
                  ))
                : user?.role === "customer"
                ? navRoutes.map((route) => (
                    <NavLink
                      key={route.href}
                      active={route.href === currentRoute}
                      to={route.href}
                    >
                      {route.text}
                    </NavLink>
                  ))
                : user?.role === "pending"
                ? [{ text: "On Boarding", href: "/" }].map((route) => (
                    <NavLink
                      key={route.href}
                      active={route.href === currentRoute}
                      to={route.href}
                    >
                      {route.text}
                    </NavLink>
                  ))
                : navRoutes.map((route) => {
                    if (route.href !== "/messages")
                      return (
                        <NavLink
                          key={route.href}
                          active={route.href === currentRoute}
                          to={route.href}
                        >
                          {route.text}
                        </NavLink>
                      );
                  })}
              {!user &&
                guestRoutes.map((route) => (
                  <NavLink
                    key={route.href}
                    active={route.href === currentRoute}
                    to={route.href}
                  >
                    {route.text}
                  </NavLink>
                ))}
            </div>
          </div>

          <div className="hidden sm:flex sm:items-center sm:ml-6">
            <div className="ml-3 relative">
              {user && (
                <Dropdown
                  title={user.name}
                  options={
                    user?.role === "pending"
                      ? [{ onClick: () => signOut(), text: "Log out" }]
                      : user?.role === "admin"
                      ? [
                          { href: "profile", text: "My Profile", logo: "" },
                          { onClick: () => signOut(), text: "Log out" },
                        ]
                      : user?.role === "seller"
                      ? [
                          { href: "profile", text: "My Profile", logo: "" },
                          { href: "orders", text: "My Orders" },
                          { onClick: () => signOut(), text: "Log out" },
                        ]
                      : [
                          { href: "profile", text: "My Profile", logo: "" },
                          { href: "wishlist", text: "My Wishlist" },
                          { href: "orders", text: "My Orders" },
                          { onClick: () => signOut(), text: "Log out" },
                        ]
                  }
                />
              )}
            </div>
          </div>

          {/* Mobile Nav Button */}
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() =>
                setShowingNavigationDropdown((previousState) => !previousState)
              }
              className="inline-flex items-center justify-center p-2 rounded-sm text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-900 focus:text-gray-500 dark:focus:text-gray-400 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  className={
                    !showingNavigationDropdown ? "inline-flex" : "hidden"
                  }
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
                <path
                  className={
                    showingNavigationDropdown ? "inline-flex" : "hidden"
                  }
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div
        className={
          (showingNavigationDropdown ? "block" : "hidden") + " sm:hidden"
        }
      >
        <div className="pt-2 pb-3 space-y-1">
          {user?.role === "admin"
            ? adminRoutes.map((route) => (
                <ResponsiveNavLink
                  active={currentRoute === route.href}
                  to={route.href}
                  key={route.href}
                  onClick={() => setShowingNavigationDropdown(false)}
                >
                  {route.text}
                </ResponsiveNavLink>
              ))
            : user?.role === "seller" || user?.role === "customer"
            ? navRoutes.map((route) => (
                <ResponsiveNavLink
                  active={currentRoute === route.href}
                  to={route.href}
                  key={route.href}
                  onClick={() => setShowingNavigationDropdown(false)}
                >
                  {route.text}
                </ResponsiveNavLink>
              ))
            : user?.role === "pending"
            ? [{ text: "On Boarding", href: "/" }].map((route) => (
                <ResponsiveNavLink
                  active={currentRoute === route.href}
                  to={route.href}
                  key={route.href}
                  onClick={() => setShowingNavigationDropdown(false)}
                >
                  {route.text}
                </ResponsiveNavLink>
              ))
            : navRoutes.map((route) => {
                if (route.href !== "/messages")
                  return (
                    <ResponsiveNavLink
                      active={currentRoute === route.href}
                      to={route.href}
                      key={route.href}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      {route.text}
                    </ResponsiveNavLink>
                  );
              })}

          {!user &&
            guestRoutes.map((route) => {
              if (route.href !== "/login")
                return (
                  <ResponsiveNavLink
                    key={route.href}
                    active={route.href === currentRoute}
                    to={route.href}
                    onClick={() => setShowingNavigationDropdown(false)}
                  >
                    {route.text}
                  </ResponsiveNavLink>
                );
            })}
        </div>
        <div className="pt-4 pb-1 border-t border-gray-200 dark:border-gray-600">
          {user ? (
            <>
              <div className="px-4">
                <div className="font-medium text-base text-gray-800 dark:text-gray-200">
                  {user.name}
                </div>
                <div className="font-medium text-sm text-gray-500">
                  {user.email}
                </div>
              </div>

              <div className="mt-3 space-y-1">
                {/* TODO: DROP DOWN */}
                {user?.role === "pending" ? (
                  <ResponsiveNavButton onClick={() => signOut()}>
                    Log Out
                  </ResponsiveNavButton>
                ) : user?.role === "admin" ? (
                  <>
                    <ResponsiveNavLink
                      active={currentRoute === "/profile"}
                      to={"/profile"}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      Profile
                    </ResponsiveNavLink>
                    <ResponsiveNavButton onClick={() => signOut()}>
                      Log Out
                    </ResponsiveNavButton>
                  </>
                ) : user?.role === "seller" ? (
                  <>
                    <ResponsiveNavLink
                      active={currentRoute === "/profile"}
                      to={"/profile"}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      Profile
                    </ResponsiveNavLink>
                    <ResponsiveNavLink
                      active={currentRoute === "/orders"}
                      to={"/orders"}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      My Orders
                    </ResponsiveNavLink>
                    <ResponsiveNavButton onClick={() => signOut()}>
                      Log Out
                    </ResponsiveNavButton>
                  </>
                ) : (
                  <>
                    <ResponsiveNavLink
                      active={currentRoute === "/profile"}
                      to={"/profile"}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      My Profile
                    </ResponsiveNavLink>
                    <ResponsiveNavLink
                      active={currentRoute === "/wishlist"}
                      to={"/wishlist"}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      My Wishlist
                    </ResponsiveNavLink>
                    <ResponsiveNavLink
                      active={currentRoute === "/orders"}
                      to={"/orders"}
                      onClick={() => setShowingNavigationDropdown(false)}
                    >
                      My Orders
                    </ResponsiveNavLink>
                    <ResponsiveNavButton onClick={() => signOut()}>
                      Log Out
                    </ResponsiveNavButton>
                  </>
                )}
              </div>
            </>
          ) : (
            <ResponsiveNavLink
              to={"/login"}
              onClick={() => setShowingNavigationDropdown(false)}
            >
              Sign In
            </ResponsiveNavLink>
          )}
        </div>
      </div>
    </nav>
  );
}
