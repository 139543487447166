import { useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import SellerShowShop from "./SellerShowShop";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { shops as shopUrl } from "../../Data/apiRoutes";
import CustomerShowShop from "./CustomerShowShop";

export default function ShowShop() {
  const params = useParams();
  const user = useSelector((state) => state.user);
  const [shop, setShop] = useState();

  const fetchShop = async () => {
    const response = await fetch(`${shopUrl}/${params.id}`);
    const data = await response.json();
    setShop(data.fetchedShop);
  };

  useEffect(() => {
    fetchShop();
  }, []);
  return (
    <MainLayout>
      {user?.role === "seller" ? (
        <SellerShowShop shop={shop} />
      ) : (
        <CustomerShowShop shop={shop} />
      )}
    </MainLayout>
  );
}
