import SecondaryButton from "../Components/SecondaryButton";

export default ({
  item,
  switchEnabled,
  hideButton = false,
  onClickAction = () => {},
}) => {
  return (
    <div className="bg-white dark:bg-gray-800 border dark:border-gray-800 hover:border-blue-500 shadow-sm sm:mx-6 my-4 sm:my-12 sm:rounded-lg">
      <div className="px-6 py-10 text-center space-y-4 bg-blue-200 dark:bg-blue-900 sm:rounded-t-lg">
        <div className="text-3xl font-black">{item.name}</div>
        <div className="text-xl font-black">
          K
          {(!switchEnabled
            ? item.price
            : item.price * 12 - item.price * 12 * 0.1
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </div>
        <div className="text-sm text-gray-700 dark:text-gray-400">
          {item.description}
        </div>
      </div>
      <div className="p-6">
        <div className="text-xl font-bold">Features</div>
        <table className="table-auto w-full pricing-table">
          <tbody>
            <tr className="border-b">
              <td className="py-2">Shop Limit</td>
              <td className="text-right py-2">
                {item.shop_limit === 9999 ? "Unlimited" : item.shop_limit}
              </td>
            </tr>
            <tr className="border-b">
              <td className="py-2">Product Limit</td>
              <td className="text-right">
                {item.product_limit === 9999 ? "Unlimited" : item.product_limit}
              </td>
            </tr>
            <tr className={`border-b ${!item.analytics ? "text-red-500" : ""}`}>
              <td className="py-2">
                <div>Analytics and reporting tools</div>
              </td>
              <td className="text-right">{item.analytics ? "Yes" : "No"}</td>
            </tr>
            <tr
              className={`border-b ${
                !item.third_party_upload ? "text-red-500" : ""
              }`}
            >
              <td className="py-2">
                <div>Third-Party product upload</div>
                <div className="text-xs text-gray-600 dark:text-gray-400">
                  Products uploaded for you by Malonda265 reps.
                </div>
              </td>
              <td className="text-right">
                {item.third_party_upload ? "Yes" : "No"}
              </td>
            </tr>

            <tr className={`border-b ${!item.messaging ? "text-red-500" : ""}`}>
              <td className="py-2">
                <div>Realtime messaging</div>
              </td>
              <td className="text-right">{item.messaging ? "Yes" : "No"}</td>
            </tr>
            <tr
              className={`border-b ${
                !item.account_manager ? "text-red-500" : ""
              }`}
            >
              <td className="py-2">
                <div>Dedicated Account Manager</div>
                <div className="text-xs text-gray-600 dark:text-gray-400">
                  Personalized support for account management.
                </div>
              </td>
              <td className="text-right">
                {item.account_manager ? "Yes" : "No"}
              </td>
            </tr>
            <tr
              className={`border-b ${
                !item.marketing_promo ? "text-red-500" : ""
              }`}
            >
              <td className="py-2">
                <div>Tailored marketing and promo strategies</div>
              </td>
              <td className="text-right">
                {item.marketing_promo ? "Yes" : "No"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {!hideButton && (
        <div className="p-6">
          <SecondaryButton
            className="w-full flex justify-center"
            onClick={onClickAction}
          >
            Select
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};
