import Container from "../../../Components/Container";
import { FaMoneyBill, FaArrowUp, FaPlus } from "react-icons/fa";
import KPIBox from "../../../Components/KPIBox";
import LineChart from "../../../Components/LineChart";
import PieCharts from "../../../Components/PieCharts";

export default function AdminHome() {
  return (
    <Container ypadding="py-2 sm:py-6" transparent>
      <div className="grid lg:grid-cols-3 gap-6">
        {["more", "less", "normal"].map((i) => (
          <KPIBox
            title="Total Sales"
            content="K 1,000,000.00"
            percentage="18"
            increment="18.5K this week"
            status={i}
            key={i}
          />
        ))}
      </div>
      <div className="w-full h-[400px] bg-gray-200 dark:bg-gray-800 rounded mt-4 pb-16">
        <div className="font-bold p-4">Revenue Trends</div>
        <LineChart />
      </div>

      <div className="lg:grid lg:grid-cols-5 gap-4">
        <div className="w-full h-[400px] bg-gray-200 dark:bg-gray-800 rounded mt-4 pb-16 col-span-3">
          <div className="font-bold p-4">Users Trend</div>
          <LineChart />
        </div>
        <div className="w-full h-[400px] bg-gray-200 dark:bg-gray-800 rounded mt-4 pb-16 col-span-2">
          <div className="font-bold p-4">Users Segmentation</div>
          <PieCharts />
        </div>
      </div>
    </Container>
  );
}
