import Modal from "../../../Components/Modal";
import InputError from "../../../Components/InputError";
import InputLabel from "../../../Components/InputLabel";
import TextInput from "../../../Components/TextInput";
import { useState, useEffect, useRef } from "react";
import TextArea from "../../../Components/TextArea";
import PrimaryButton from "../../../Components/PrimaryButton";
import Select from "../../../Components/Select";
import { toast } from "react-toastify";
import { useForm } from "../../../Hooks/useForm";
import { isString } from "../../../Functions/functions";

export default ({
  show,
  categories,
  product,
  setProduct,
  setIsProductVariantModal,
  close,
  shops,
}) => {
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const {
    values: data,
    handleChange,
    reset,
    change,
  } = useForm({
    id: product?.id,
    user_id: product?.user_id,
    picture: undefined,
    name: product?.name,
    price: product?.price,
    discount: product?.discount,
    is_featured: product?.is_featured ? "yes" : "no",
    description: product?.description,
    category: "",
    shop: "",
  });

  useEffect(() => {
    const category = categories?.find((cat) => cat.id === product?.category_id);
    const cat_text = category?.id + "-" + category?.name;
    change("category", cat_text);

    const shop = shops?.find((shop) => shop.id === product?.shop_id);
    const shop_text = shop?.id + "-" + shop?.name;
    change("shop", shop_text);
  }, [categories, product, shops]);

  const selectImage = () => {
    imageRef.current.click();
  };

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleChange(e);
      setSelectedImage(e.target.files[0]);
      return;
    }
    handleChange(e);
  };

  const nextPage = () => {
    setProduct({
      ...data,
      picture: selectedImage,
      photo: product?.picture,
    });
    close();
    setIsProductVariantModal((s) => !s);
  };

  return (
    <Modal
      show={show}
      title="Update Product"
      close={close}
      customOk={<PrimaryButton onClick={nextPage}>Next</PrimaryButton>}
      maxWidth="max-w-4xl"
    >
      <div className="sm:grid grid-cols-4 gap-2">
        <div className="flex justify-center">
          <div className="w-[150px] h-[150px] relative overflow-hidden">
            <TextInput
              id="picture"
              name="picture"
              type="file"
              accept="image/jpeg,image/jpg,image/png"
              className="hidden"
              ref={imageRef}
              onChange={onChange}
            />
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : product?.picture
                  ? isString(product?.picture)
                    ? `${
                        process.env.REACT_APP_BACKEND_URL
                      }/${product?.picture?.replace(/"/g, "")}`
                    : URL.createObjectURL(product?.picture)
                  : "/img/image_icon1.png"
              }
              className="w-[150px] h-[150px] object-cover"
              alt="shop photo"
            />
            <div
              onClick={selectImage}
              className="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
            >
              <div>Change Image</div>
            </div>

            <div
              className="cursor-pointer absolute z-40 bottom-1 right-1 bg-blue-500 p-1 rounded"
              onClick={selectImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="fill-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="sm:grid grid-cols-6 col-span-3 gap-2">
          <div className="col-span-3">
            <InputLabel htmlFor="name" value="Product Name *" />

            <TextInput
              id="name"
              name="name"
              className="mt-1 block w-full"
              value={data.name}
              onChange={onChange}
            />

            <InputError
              // message={errors?.name}
              className="mt-2 capitalize"
            />
          </div>

          <div className="col-span-3">
            <InputLabel htmlFor="price" value="Product Price(MWK) *" />

            <TextInput
              id="price"
              name="price"
              type="number"
              step="0.01"
              className="mt-1 block w-full"
              value={data.price}
              onChange={onChange}
            />

            <InputError
              // message={errors?.price}
              className="mt-2 capitalize"
            />
          </div>

          <div className="col-span-6 grid grid-cols-4 md:grid-cols-8 gap-2">
            <div className="col-span-2">
              <InputLabel htmlFor="shop">Shop</InputLabel>

              <Select
                id="shop"
                name="shop"
                options={shops?.map((shop) => shop.id + "-" + shop.name)}
                value={data.shop}
                className="mt-1 block w-full"
                onChange={onChange}
              />

              <InputError
                // message={errors?.name}
                className="mt-2 capitalize"
              />
            </div>

            <div className="col-span-2">
              <InputLabel htmlFor="category">Category</InputLabel>

              <Select
                id="category"
                name="category"
                options={categories?.map(
                  (category) => category.id + "-" + category.name
                )}
                value={data.category}
                className="mt-1 block w-full"
                onChange={onChange}
              />

              <InputError
                // message={errors?.name}
                className="mt-2 capitalize"
              />
            </div>

            <div className="col-span-2">
              <InputLabel htmlFor="is_featured" value="Featured? *" />

              <Select
                id="is_featured"
                name="is_featured"
                options={["yes", "no"]}
                className="mt-1 block w-full"
                value={data.is_featured}
                onChange={onChange}
              />

              <InputError
                // message={errors?.name}
                className="mt-2 capitalize"
              />
            </div>

            <div className="col-span-2">
              <InputLabel htmlFor="discount" value="Discount(MWK)" />

              <TextInput
                id="discount"
                name="discount"
                type="number"
                step="0.01"
                className="mt-1 block w-full"
                value={data.discount}
                onChange={onChange}
              />

              <InputError
                // message={errors?.discount}
                className="mt-2 capitalize"
              />
            </div>
          </div>
        </div>

        <div className="col-span-4">
          <InputLabel htmlFor="description" value="Product Description" />

          <TextArea
            id="description"
            name="description"
            className="mt-1 block w-full"
            rows={6}
            value={data.description}
            onChange={onChange}
          />

          <InputError
            // message={errors?.description}
            className="mt-2 capitalize"
          />
        </div>
      </div>
    </Modal>
  );
};
