import { useEffect } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

export default ({ children, className = "" }) => {
  // Inititalize Datables
  useEffect(() => {
    $(document).ready(function () {
      setTimeout(function () {
        $("table.data-table").DataTable({
          language: {
            emptyTable: "  ",
          },
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          paging: true,
          responsive: true,
          lengthChange: true,
          autoWidth: true,
          searching: true,
          ordering: true,
          buttons: [
            {
              extend: "copyHtml5",
            },
            {
              extend: "excelHtml5",
            },
            {
              extend: "pdfHtml5",
            },
            {
              extend: "print",
            },
          ],
        });
      }, 1000);
    });
  }, []);

  return <div className={className}>{children}</div>;
};
