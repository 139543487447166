import { useNavigate } from "react-router-dom";
import Container from "../../Components/Container";
import MainLayout from "../../Layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import SubscriptionPackage from "../../Widgets/SubscriptionPackage";
import {
  classNames,
  dateFormat,
  numberFormat,
} from "../../Functions/functions";
import { GrCertificate } from "react-icons/gr";
import { Tab } from "@headlessui/react";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import InputError from "../../Components/InputError";
import PrimaryButton from "../../Components/PrimaryButton";
import { toast } from "react-toastify";
import axios from "axios";
import { subscriptions as subscriptionURL } from "../../Data/apiRoutes";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const subscriptionType = useSelector(
    (state) => state.selectedSubscriptionType
  );
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("Airtel Money");
  const [processing, setProcessing] = useState(false);

  const canPay = useMemo(() => {
    if (paymentMethod === "VISA") {
      if (
        !!data?.card_name &&
        !!data?.card_number &&
        !!data?.card_expiry &&
        !!data?.card_cvv
      ) {
        return true;
      }
    }

    if (paymentMethod === "Airtel Money") {
      if (
        data?.payment_number?.startsWith("2659", 0) &&
        data?.payment_number.length === 12
      ) {
        return true;
      }
    }

    if (paymentMethod === "Mpamba") {
      if (
        data?.payment_number?.startsWith("2658", 0) &&
        data?.payment_number.length === 12
      ) {
        return true;
      }
    }

    return false;
  }, [data, paymentMethod]);

  const handleOnChange = (e) => {
    if (e.target.name === "card_cvv" && isNaN(e.target.value)) return;

    if (e.target.name === "card_number") {
      if (isNaN(e.target.value.replace(/\s/g, ""))) return;
      else {
        let value = e.target.value;

        // Remove any existing spaces and limit the input to 16 characters
        value = value.replace(/\s/g, "").substring(0, 16);

        // Add a space every 4 characters
        value = value.replace(/(.{4})/g, "$1 ");

        if (value.replace(/\s/g, "").length === 16)
          value = value.substring(0, 19);

        setData({
          ...data,
          [e.target.name]: value,
        });
        return;
      }
    }
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const proceedPayment = async () => {
    setProcessing(true);
    try {
      const expiry = new Date();
      expiry.setDate(
        expiry.getDate() + (subscriptionType.sub_type === "annually" ? 365 : 31)
      );
      const body = {
        user_id: user.id,
        subscription_id: subscriptionType.id,
        expired_at: expiry,
        price:
          subscriptionType.sub_type === "annually"
            ? subscriptionType.price * 12 - subscriptionType.price * 12 * 0.1
            : subscriptionType.price,
        ...data,
        method: paymentMethod,
      };

      const response = await axios.post(subscriptionURL, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.statusText === "OK") {
        toast.success("Subscription done successfully!");
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    setProcessing(false);
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    if (user?.role !== "seller") {
      const handler = setTimeout(() => {
        toast.warning("Only Suppliers/Sellers can have subscriptions!");
      }, 2000);
      return () => clearTimeout(handler);
    }
  }, [user]);
  return (
    <MainLayout>
      <div className="grid grid-cols-4 content-stretch justify-stretch items-stretch">
        <SubscriptionPackage
          item={subscriptionType}
          switchEnabled={subscriptionType.sub_type === "annually"}
          hideButton
        />
        <div className="col-span-3">
          <Container xpadding="" ypadding="pt-4 sm:pt-12">
            <div>
              <div className="text-xl font-bold">Current Subscription</div>
              <div className="text-gray-500">
                Expires on{" "}
                {user?.Subscriptions?.length > 0
                  ? dateFormat(user?.Subscriptions[0]?.expired_at)
                  : "-"}
              </div>

              <div className="border dark:border-gray-600 p-4 rounded flex gap-4 items-center">
                <GrCertificate />
                <div className="font-bold">
                  {user?.Subscriptions?.length > 0
                    ? user?.Subscriptions[0]?.SubscriptionType?.name
                    : " - "}
                </div>
                <div className="ms-auto">
                  MWK{" "}
                  {user?.Subscriptions?.length > 0
                    ? numberFormat(
                        user?.Subscriptions[0]?.SubscriptionType?.price
                      )
                    : " - "}
                </div>
              </div>
            </div>
          </Container>

          <Container xpadding="" ypadding="pt-4 sm:pt-12">
            <Tab.Group>
              <Tab.List className="border-b border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400">
                {["Airtel Money", "Mpamba", "VISA", "Other"].map((tab) => (
                  <Tab
                    onClick={(e) => {
                      setPaymentMethod(tab);
                    }}
                    className={({ selected }) =>
                      classNames(
                        "outline-none transition-all p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300",
                        !selected
                          ? ""
                          : tab === "Mpamba"
                          ? "text-green-600 border-b-2 border-green-600 rounded-t-lg active dark:text-green-500 dark:border-green-500"
                          : tab === "Airtel Money"
                          ? "text-red-600 border-b-2 border-red-600 rounded-t-lg active dark:text-red-500 dark:border-red-500"
                          : "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      )
                    }
                  >
                    <div className="font-bold">{tab}</div>
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className={"pt-2"}>
                {/* Airtel Money */}
                <Tab.Panel>
                  <div className="my-3">
                    <InputLabel
                      htmlFor="payment_number"
                      value="Phone Number:"
                    />

                    <TextInput
                      id="payment_number"
                      type="text"
                      name="payment_number"
                      value={data.payment_number}
                      className="mt-1 block w-full"
                      autoComplete="payment_number"
                      onChange={handleOnChange}
                      placeholder={"26599..."}
                    />

                    <InputError
                      message={errors?.payment_number}
                      className="mt-2"
                    />
                  </div>
                </Tab.Panel>
                {/* Mpamba */}
                <Tab.Panel>Coming Soon!</Tab.Panel>
                {/* VISA */}
                <Tab.Panel>Coming Soon!</Tab.Panel>
                {/* Other */}
                <Tab.Panel>Coming Soon!</Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </Container>
          <Container transparent padding="p-1" xpadding="">
            <div className="flex justify-end">
              <PrimaryButton
                disabled={!canPay || processing}
                onClick={proceedPayment}
              >
                Proceed Payment
              </PrimaryButton>
            </div>
          </Container>
        </div>
      </div>
    </MainLayout>
  );
};
