import Container from "../../Components/Container";
import MainLayout from "../../Layouts/MainLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsMessenger, setRecipient } from "../../Context/reducer";
import MessagesWidget from "../../Widgets/MessagesWidget";
import { chats as chatsRoute } from "../../Data/apiRoutes";
import TimeAgo from "react-timeago";
import { useNavigate } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);

  const getChats = async () => {
    const response = await fetch(`${chatsRoute}/${user.id}`);
    const data = await response.json();
    setChats(data.chats);
    console.log(data.chats);
  };

  const setActiveUser = (chat) => {
    setActiveChat(chat);
    const rec = {
      id: chat.uid,
      name: chat.name,
    };
    dispatch(setRecipient(rec));
  };

  const setActiveUserOnMobile = (chat) => {
    setActiveChat(chat);
    const rec = {
      id: chat.uid,
      name: chat.name,
    };

    dispatch(setRecipient(rec));
    navigate(`/messages/${chat.conversation_id}`);
  };

  useEffect(() => {
    dispatch(setIsMessenger(false));
    getChats();
  }, []);

  return (
    <MainLayout noFooter top="">
      <div className="lg:grid grid-cols-6">
        <div className="col-span-2">
          <Container>
            <div>
              <div className="flex flex-col">
                <input
                  type="search"
                  className="dark:bg-gray-900 p-2 dark:text-gray-300 border-b-2 border-gray-300 dark:border-gray-700 focus:border-blue-500 dark:focus:border-blue-600 ring-0 outline-none w-full shadow-sm"
                  placeholder="Search..."
                />
              </div>
              <div className="mt-4 h-[70vh] overflow-auto relative">
                {!!chats &&
                  chats.length > 0 &&
                  chats.map((chat) => (
                    <>
                      <div
                        onClick={() => setActiveUser(chat)}
                        className={
                          "hidden lg:flex gap-2 items-center cursor-pointer rounded shadow-sm p-2 my-2 border sm:border-none dark:border-gray-900 hover:bg-slate-100 hover:dark:bg-slate-900 " +
                          (chat === activeChat
                            ? " bg-slate-200 dark:bg-slate-950"
                            : "")
                        }
                      >
                        <img
                          src="/img/user-icon.png"
                          className="h-10 w-10 object-cover rounded-full"
                        />
                        <div className="w-full">
                          <div className="font-bold">{chat.name}</div>
                          <div className="flex">
                            <div className="text-xs text-gray-500">
                              {chat.message.length > 50
                                ? chat.message.substr(0, 50) + "..."
                                : chat.message}
                            </div>
                            <div className="text-xs ms-auto">
                              <TimeAgo date={chat.createdAt} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => setActiveUserOnMobile(chat)}
                        className={
                          "flex lg:hidden gap-2 items-center cursor-pointer rounded shadow-sm p-2 my-2 border sm:border-none dark:border-gray-900 hover:bg-slate-100 hover:dark:bg-slate-900 " +
                          (chat === activeChat
                            ? " bg-slate-200 dark:bg-slate-950"
                            : "")
                        }
                      >
                        <img
                          src="/img/user-icon.png"
                          className="h-10 w-10 object-cover rounded-full"
                        />
                        <div className="w-full">
                          <div className="font-bold">{chat.name}</div>
                          <div className="flex">
                            <div className="text-xs text-gray-500">
                              {chat.message.length > 50
                                ? chat.message.substr(0, 50) + "..."
                                : chat.message}
                            </div>
                            <div className="text-xs ms-auto">
                              <TimeAgo date={chat.createdAt} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {chats.length === 0 && (
                  <div className="absolute top-1/2  inset-y-1/2 w-full text-center">
                    No&nbsp;Chats
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
        <div className="col-span-4">
          {activeChat ? (
            <MessagesWidget />
          ) : (
            <div className="hidden lg:inline-block w-full">
              <Container padding="" size="w-full">
                <div className="flex gap-4 rounded shadow-sm p-2 items-center">
                  <div className="h-14 object-cover rounded-full" />
                </div>
                <div className="p-4 h-[66vh] overflow-y-auto flex flex-col items-center justify-center">
                  Select a Conversation on the left panel!
                </div>
                <div className="flex gap-4 rounded shadow-sm p-2 items-center">
                  <div className="h-14 object-cover rounded-full" />
                </div>
              </Container>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
