import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "../Assets/Svg/close.svg";
import MinusIcon from "../Assets/Svg/minus.svg";
import PlusIcon from "../Assets/Svg/plus.svg";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  removeFromCart,
  incrementCartQuantity,
  decrementCartQuantity,
} from "../Context/reducer";

export default function Cart({ close, show, props }) {
  const [total, setTotal] = useState(0);
  const cart = useSelector((s) => s.cart);
  const isAuth = Boolean(useSelector((state) => state.token));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeItem = (item) => {
    dispatch(removeFromCart(item));
  };

  const incrementItem = (item) => {
    dispatch(incrementCartQuantity(item));
  };

  const decrementItem = (item) => {
    dispatch(decrementCartQuantity(item));
  };

  const checkout = () => {
    close();
    if (!isAuth) {
      navigate("/login");
    } else {
      navigate("/checkout");
    }
  };

  useEffect(() => {
    var sum = 0;
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      sum += element.unit_price * element.quantity;
    }
    setTotal(sum);
  }, [cart]);

  return (
    <div
      {...props}
      className={`h-full w-full md:w-96 flex flex-col shadow fixed top-0 bottom-0 right-0 p-5 bg-gray-200 dark:bg-gray-900 text-gray-900 dark:text-gray-300 md:border-l border-gray-200 dark:border-gray-700 transition-all duration-300 ${
        show ? "transform-none" : "translate-x-[900px]"
      }`}
      style={{ zIndex: 9999 }}
    >
      <div className="flex justify-between items-center mb-4">
        <div className="text-xl font-bold">My Cart</div>
        <button onClick={close} className="text-xl font-bold">
          &times;
        </button>
      </div>

      <ul
        className={`flex-grow overflow-auto py-4 ${
          total === 0 ? "flex justify-center items-center" : ""
        }`}
      >
        {/* Cart Item */}
        {!!cart &&
          cart.map((item) => (
            <li
              key={item}
              className={`flex w-full flex-col border-b border-gray-300 dark:border-gray-700`}
            >
              <div className="relative flex w-full flex-row justify-between px-1 py-4">
                <div className="absolute z-40 -mt-2 ml-[55px]">
                  <button
                    className="ease flex h-[17px] w-[17px] items-center justify-center rounded-full bg-gray-500 transition-all duration-200"
                    onClick={() => removeItem(item)}
                  >
                    <img src={CloseIcon} />
                  </button>
                </div>

                <Link
                  to={`/products/${item.product_id}`}
                  className="z-30 flex flex-row space-x-4"
                >
                  <div className="relative h-16 w-16 cursor-pointer overflow-hidden rounded-md border border-gray-300 bg-gray-300 dark:border-gray-700 dark:bg-gray-900 dark:hover:bg-gray-800">
                    <img
                      layout="fill"
                      objectFit="cover"
                      src={
                        `${
                          process.env.REACT_APP_BACKEND_URL
                        }/${item?.picture?.replace(/"/g, "")}` ||
                        "/img/image_icon1.png"
                      }
                    />
                  </div>
                  <div className="flex flex-1 flex-col text-base">
                    <span className="leading-tight">{item.name}</span>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {item.color} / {item.size}
                    </p>
                  </div>
                </Link>

                <div className="flex h-16 flex-col justify-between">
                  <p className="flex justify-end space-y-2 text-right text-sm">
                    {item.unit_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                    <span class="ml-1 inline">MWK</span>
                  </p>
                  <div className="ml-auto flex h-9 flex-row items-center rounded-full border border-gray-200 dark:border-gray-700">
                    <button
                      onClick={() => decrementItem(item)}
                      disabled={item.quantity <= 1}
                      className={`ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 ${
                        item.quantity > 1
                          ? "bg-blue-500 hover:border-blue-800 hover:opacity-80"
                          : "bg-gray-500"
                      } ml-auto`}
                    >
                      <img
                        className="h-full w-full object-cover"
                        src={MinusIcon}
                      />
                    </button>
                    <p class="w-6 text-center">
                      <span class="w-full text-sm">{item.quantity}</span>
                    </p>
                    <button
                      onClick={() => incrementItem(item)}
                      disabled={item.quantity >= item.max}
                      className={`ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 ${
                        item.quantity < item.max
                          ? "bg-blue-500 hover:border-blue-800 hover:opacity-80"
                          : "bg-gray-500"
                      } ml-auto`}
                    >
                      <img
                        className="h-full w-full object-cover"
                        src={PlusIcon}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        {total === 0 && <li className="self-center">Your Cart is Empty</li>}
      </ul>

      <div className="mt-auto">
        <div className="py-4 text-sm text-gray-500 dark:text-gray-400">
          <div className="mb-3 flex items-center justify-between border-b border-gray-200 pb-1 pt-1 dark:border-gray-700">
            <p>Total</p>
            <p className="text-right text-base text-black dark:text-white">
              {total.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
              <span className="ml-1 inline">MWK</span>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <SecondaryButton
            className="w-full flex justify-center"
            onClick={close}
          >
            Close
          </SecondaryButton>
          <PrimaryButton
            className="w-full flex justify-center"
            disabled={total === 0}
            onClick={checkout}
          >
            Proceed
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
