export default ({ className = "", height, width, fill }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 313.000000 148.000000`}
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1471 1466 c-55 -31 -65 -93 -22 -137 38 -37 82 -39 116 -4 39 38 37
 88 -6 128 -32 30 -52 33 -88 13z"
        />
        <path
          d="M2120 1387 l-45 -12 0 -290 c0 -261 2 -293 18 -323 24 -43 85 -71
 155 -72 l53 0 -3 42 c-3 38 -6 43 -35 50 -18 5 -37 17 -43 28 -6 12 -9 81 -8
 177 l3 158 40 5 c40 5 40 5 43 52 l3 47 -43 3 -43 3 -3 73 c-3 81 -4 82 -92
 59z"
        />
        <path
          d="M2970 1393 c-14 -2 -35 -6 -48 -9 l-23 -5 3 -304 3 -303 25 -27 c38
 -41 73 -55 135 -55 l56 0 -3 42 c-3 37 -6 43 -33 50 -54 14 -55 18 -55 330 0
 317 5 292 -60 281z"
        />
        <path
          d="M341 1364 c-69 -18 -178 -76 -232 -122 -128 -110 -135 -252 -11 -252
 50 0 100 23 216 98 124 82 207 97 235 43 33 -60 -10 -163 -109 -261 -62 -62
 -169 -130 -204 -130 -30 0 -16 35 34 92 56 63 61 81 26 103 -62 41 -166 -57
 -166 -156 0 -92 74 -112 192 -53 184 92 320 246 348 392 16 84 1 132 -59 195
 -60 63 -158 81 -270 51z"
        />
        <path
          d="M1052 1250 c-55 -13 -122 -41 -122 -51 0 -16 30 -69 39 -69 5 0 25 7
 44 15 20 8 61 15 91 15 48 0 58 -3 71 -24 9 -14 15 -37 13 -53 -3 -28 -3 -28
 -93 -33 -108 -6 -148 -26 -182 -89 -42 -80 -23 -176 46 -234 63 -53 188 -59
 287 -13 86 40 84 35 84 224 -1 219 -16 262 -105 303 -42 19 -114 23 -173 9z
 m126 -292 c7 -7 12 -42 12 -89 0 -86 -3 -89 -71 -89 -78 0 -117 82 -68 144 29
 37 104 57 127 34z"
        />
        <path
          d="M1843 1242 c-29 -9 -72 -31 -97 -49 l-45 -32 -3 -223 c-2 -123 -1
 -229 1 -235 2 -9 25 -13 67 -13 l64 0 0 214 c0 258 -5 248 130 258 30 3 36 8
 49 41 7 20 11 40 8 43 -15 15 -125 13 -174 -4z"
        />
        <path
          d="M2527 1247 c-97 -31 -157 -125 -165 -258 -13 -233 136 -354 356 -289
 21 7 49 20 62 30 24 18 24 19 7 54 l-17 37 -32 -17 c-49 -25 -151 -31 -184
 -10 -33 22 -56 77 -52 124 l3 37 148 3 147 3 0 47 c0 99 -46 194 -110 227 -39
 20 -119 26 -163 12z m111 -115 c17 -22 26 -58 18 -77 -4 -11 -22 -15 -75 -15
 -38 0 -72 4 -76 10 -9 15 21 76 46 94 28 19 67 14 87 -12z"
        />
        <path
          d="M1497 1238 l-57 -10 2 -267 3 -266 62 -3 c51 -2 62 0 65 14 6 33 -3
 544 -10 543 -4 -1 -33 -6 -65 -11z"
        />
        <path
          d="M897 373 c-4 -3 -7 -62 -7 -130 0 -113 2 -124 18 -121 14 3 18 18 22
 95 6 102 22 133 71 133 46 0 59 -31 59 -137 0 -50 4 -94 8 -97 21 -13 30 16
 33 102 4 84 6 96 28 113 29 24 63 24 84 1 13 -15 17 -39 17 -114 0 -73 3 -97
 14 -101 8 -3 17 -3 20 0 3 4 6 55 6 115 0 95 -2 110 -20 128 -25 25 -96 27
 -129 4 -22 -16 -25 -16 -51 0 -34 20 -88 20 -114 1 -17 -13 -22 -13 -36 0 -9
 8 -20 12 -23 8z"
        />
        <path
          d="M1381 355 c-52 -44 -62 -123 -25 -196 28 -57 142 -66 192 -15 36 36
 43 135 13 184 -37 58 -126 71 -180 27z m126 -20 c49 -34 49 -146 0 -180 -34
 -23 -80 -19 -108 11 -19 20 -24 36 -24 79 0 43 5 59 24 79 28 30 74 34 108 11z"
        />
        <path
          d="M1652 371 c-9 -6 -12 -39 -10 -127 3 -104 5 -119 21 -122 15 -3 17 5
 17 61 0 109 31 167 91 167 46 0 59 -30 59 -137 0 -93 5 -108 29 -94 10 7 12
 34 9 116 -3 98 -6 109 -27 126 -28 23 -97 25 -125 4 -17 -13 -22 -13 -36 0 -9
 8 -21 11 -28 6z"
        />
        <path
          d="M1991 367 c-35 -17 -64 -72 -64 -121 0 -83 51 -136 131 -136 42 0 92
 30 92 56 0 20 -24 17 -45 -6 -39 -43 -135 -7 -135 50 0 19 6 20 95 20 78 0 97
 3 102 16 10 25 -16 91 -44 113 -29 24 -94 28 -132 8z m115 -44 c13 -14 23 -31
 21 -37 -4 -12 -120 -21 -144 -12 -12 5 -11 10 1 35 26 50 83 56 122 14z"
        />
        <path
          d="M2402 367 c-5 -7 -24 -49 -43 -94 -18 -46 -38 -83 -44 -83 -6 0 -27
 39 -47 88 -23 57 -42 88 -53 90 -24 5 -18 -17 36 -145 l42 -101 -21 -53 c-15
 -36 -19 -56 -12 -63 6 -6 13 -6 19 -1 8 9 161 350 161 359 0 11 -30 13 -38 3z"
        />
      </g>
    </svg>
  );
};
