import Container from "../../../Components/Container";
import Dropdown from "../../../Components/DropDown";
import EditIcon from "../../../Assets/JSX/EditIcon";
import {
  dateFormat,
  timeFormat,
  numberFormat,
  padZeros,
  classNames,
} from "../../../Functions/functions";
import { Tab } from "@headlessui/react";
import { useMemo, useState } from "react";
import Loader from "../../../Components/Loader";
import { Link } from "react-router-dom";

export default ({ orders, isLoading }) => {
  const [filter, setFilter] = useState("All");
  const displayList = (orders) => {
    if (!isLoading) {
      return (
        <>
          <div className="grid grid-cols-6 font-bold">
            <div className="px-4 py-2">Order #</div>
            <div className="px-4 py-2">Receipient</div>
            <div className="px-4 py-2">Status</div>
            <div className="px-4 py-2">Total</div>
            <div className="px-4 py-2">Created At</div>
            <div className="px-4 py-2">&nbsp;</div>
          </div>
          {!!orders && orders?.length > 0 ? (
            orders?.map((order) => (
              <div
                key={order.id}
                className="grid grid-cols-6 bg-white border p-2 mb-2 rounded dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
              >
                <Link
                  to={`/orders/${order.id}`}
                  className="px-4 py-2 text-gray-900 dark:text-white font-bold underline underline-offset-2"
                >
                  #MO-{padZeros(order.id, 7)}
                </Link>
                <div className="px-4 py-2 text-gray-900 dark:text-white">
                  {order.recipient_name}
                </div>
                <div className="px-4 py-2 text-gray-900 dark:text-white capitalize">
                  {order.status === "new" ? "Not Paid" : order.status}
                </div>
                <div className="px-4 py-2 text-gray-900 dark:text-white">
                  K{numberFormat(order.subtotal)}
                </div>
                <div className="px-4 py-2 text-gray-900 dark:text-white">
                  {dateFormat(order.createdAt)}
                </div>
                <div className="px-4 py-2 text-gray-900 dark:text-white">
                  <div className="flex justify-center gap-5">
                    <button>
                      <EditIcon className="fill-gray-900 dark:fill-gray-200" />
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center p-4 border bg-white dark:bg-gray-800 dark:border-gray-700">
              No Orders in this category
            </div>
          )}
        </>
      );
    } else {
      return <Loader />;
    }
  };

  const filteredOrders = useMemo(() => {
    switch (filter) {
      case "All":
        return orders;
      case "Unpaid":
        return orders?.filter((order) => order.status === "new");
      case "Paid":
        return orders?.filter((order) => order.status === "paid");
      case "Completed":
        return orders?.filter((order) => order.status === "completed");
      case "Cancelled":
        return orders?.filter(
          (order) =>
            order.status === "pending-cancel" || order.status === "cancelled"
        );
    }
  }, [filter, orders]);

  return (
    <Container className="min-h-[50vh]">
      {/* DESKTOP VIEW */}
      <div className="hidden md:block">
        <div className="text-xl font-bold">My Orders</div>
        <Tab.Group>
          <Tab.List className="border-b border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400">
            {["All", "Unpaid", "Paid", "Completed", "Cancelled"].map((tab) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "outline-none transition-all p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300",
                    !selected
                      ? ""
                      : tab === "Unpaid"
                      ? "text-yellow-600 border-b-2 border-yellow-600 rounded-t-lg active dark:text-yellow-500 dark:border-yellow-500"
                      : tab === "Paid"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : tab === "Completed"
                      ? "text-green-600 border-b-2 border-green-600 rounded-t-lg active dark:text-green-500 dark:border-green-500"
                      : tab === "Cancelled"
                      ? "text-red-600 border-b-2 border-red-600 rounded-t-lg active dark:text-red-500 dark:border-red-500"
                      : "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className={"pt-2"}>
            {/* All Orders */}
            <Tab.Panel>{displayList(orders)}</Tab.Panel>
            {/* Unpaid Orders */}
            <Tab.Panel>
              {displayList(orders?.filter((order) => order.status === "new"))}
            </Tab.Panel>
            {/* Paid Orders */}
            <Tab.Panel>
              {displayList(orders?.filter((order) => order.status === "paid"))}
            </Tab.Panel>
            {/* Completed Orders */}
            <Tab.Panel>
              {displayList(
                orders?.filter((order) => order.status === "completed")
              )}
            </Tab.Panel>
            {/* Cancelled Orders */}
            <Tab.Panel>
              {displayList(
                orders?.filter(
                  (order) =>
                    order.status === "pending-cancel" ||
                    order.status === "cancelled"
                )
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      {/* END DESKTOP VIEW */}

      {/* MOBILE VIEW */}
      <div className="block md:hidden">
        <div className="flex justify-between">
          <div className="text-xl font-bold">My Orders</div>
          <Dropdown
            title={"Filter"}
            options={[
              { onClick: () => setFilter("All"), text: "All" },
              { onClick: () => setFilter("Unpaid"), text: "Unpaid" },
              { onClick: () => setFilter("Paid"), text: "Paid" },
              { onClick: () => setFilter("Completed"), text: "Completed" },
              { onClick: () => setFilter("Cancelled"), text: "Cancelled" },
            ]}
          />
        </div>
        {filteredOrders?.length > 0 ? (
          filteredOrders?.map((order) => (
            <Link
              to={`/orders/${order.id}`}
              key={order.id}
              className="bg-white border p-4 mb-1 rounded dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
            >
              <div className="text-gray-900 dark:text-white font-bold">
                Order#:{" "}
                <span className=" underline underline-offset-2">
                  #MO-{padZeros(order.id, 7)}
                </span>
              </div>
              <div className="text-gray-900 dark:text-white">
                {dateFormat(order.createdAt)} {timeFormat(order.createdAt)}
              </div>
              <div className="flex justify-between mt-3 text-sm">
                <div className="text-gray-900 dark:text-white capitalize">
                  {order.status === "new" ? "Not Paid" : order.status}
                </div>
                <div className="text-gray-900 dark:text-white font-bold">
                  K{numberFormat(order.subtotal)}
                </div>
              </div>
              <div className="text-gray-700 dark:text-gray-300 text-xs italic">
                Recipient: {order.recipient_name}
              </div>
            </Link>
          ))
        ) : (
          <div className="text-center p-4 border bg-white dark:bg-gray-800 dark:border-gray-700">
            No Orders in this category
          </div>
        )}
      </div>
      {/* END MOBILE VIEW */}
    </Container>
  );
};
