import { useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import SellerShop from "./SellerShop";
import CustomerShop from "./CustomerShop";

export default function Shops() {
  const user = useSelector((state) => state.user);
  return (
    <MainLayout>
      {user?.role === "seller" ? <SellerShop /> : <CustomerShop />}
    </MainLayout>
  );
}
