import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "January",
    Customers: 4000,
    Purchases: 2400,
    amt: 2400,
  },
  {
    name: "February",
    Customers: 3000,
    Purchases: 1398,
    amt: 2210,
  },
  {
    name: "March",
    Customers: 2000,
    Purchases: 9800,
    amt: 2290,
  },
  {
    name: "April",
    Customers: 2780,
    Purchases: 3908,
    amt: 2000,
  },
  {
    name: "May",
    Customers: 1890,
    Purchases: 4800,
    amt: 2181,
  },
  {
    name: "June",
    Customers: 2390,
    Purchases: 3800,
    amt: 2500,
  },
  {
    name: "July",
    Customers: 3490,
    Purchases: 4300,
    amt: 2100,
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-200 dark:bg-gray-700 shadow p-2">
        <p className="font-bold">{label}</p>
        {payload.map((load) => (
          <p className="text-sm">{`${load.name} : ${load.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export default () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line type="monotone" dataKey="Purchases" stroke="#8884d8" />
        <Line type="monotone" dataKey="Customers" stroke="#82ca9d" />
        <Line type="monotone" dataKey="amt" stroke="maroon" />
      </LineChart>
    </ResponsiveContainer>
  );
};
