import { useSelector } from "react-redux";
import { orders as ordersURL } from "../../Data/apiRoutes";
import MainLayout from "../../Layouts/MainLayout";
import { useEffect, useState } from "react";
import CustomerOrders from "./Customer";
import SellerOrders from "./Seller";

export default function Orders() {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${ordersURL}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setOrders(data.orders))
      .catch((error) => console.log(error));
    setIsLoading(false);
  }, [user, token]);

  return (
    <MainLayout>
      {user?.role === "customer" ? (
        <CustomerOrders orders={orders} isLoading={isLoading} />
      ) : user?.role === "seller" ? (
        <SellerOrders orders={orders} />
      ) : (
        <></>
      )}
    </MainLayout>
  );
}
