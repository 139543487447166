import { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsMessenger,
  removeRecipient,
  setRecipient,
} from "../Context/reducer";
import TimeAgo from "react-timeago";
import io from "socket.io-client";
import { chatSocketUrl as socketUrl } from "../Data/apiRoutes";
import { IoExpandOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { dateFormat, timeFormat } from "../Functions/functions";

export default function Messenger() {
  const [socket, setSocket] = useState(null);
  const [convoID, setConvoID] = useState("");
  const [outMessage, setOutMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const chatboxRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user);
  const recipient = useSelector((s) => s.recipient);
  const templateMessage = useSelector((s) => s.templateMessage);
  const navigate = useNavigate();

  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  const expand = () => {
    dispatch(setRecipient(recipient));
    navigate(`/messages/${convoID}`);
    dispatch(setIsMessenger(false));
  };

  const close = () => {
    dispatch(removeRecipient());
    dispatch(setIsMessenger(false));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (socket && outMessage) {
      const data = {
        conversation_id: convoID,
        user_id: user.id,
        actual_msg: outMessage,
        status: "unseen",
        type_of_msg: "text",
      };
      socket.emit("message sent", data);
      setOutMessage("");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setOutMessage(templateMessage);
    console.log(templateMessage);
  }, [templateMessage]);

  useEffect(() => {
    const socketInstance = io(`${socketUrl}`);
    setSocket(socketInstance);

    socketInstance.on("connect", () => {
      socketInstance.emit("getChatId", { user1: user, user2: recipient });

      console.log("Connected to server");
    });

    socketInstance.on("chatIdResponse", (data) => {
      setConvoID(data);
      socketInstance.emit("user chat", data);
    });

    socketInstance.on("message all", (data) => {
      setMessages(data);
    });

    socketInstance.on("message received", (data) => {
      setMessages((s) => [...s, data]);
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [recipient]);
  return (
    <>
      <div
        id="chat-container"
        className="fixed bottom-0 sm:bottom-8 right-0 left-0 sm:left-auto sm:right-28 sm:w-96 w-full z-50"
      >
        <div className="bg-white dark:bg-gray-950 shadow-md border-gray-500 border rounded-lg max-w-lg w-full">
          <div className="p-4 border-b bg-blue-500 dark:bg-gray-800 text-white rounded-t-lg flex justify-between items-center">
            <p className="text-lg font-semibold">{recipient?.name}</p>

            <div className="flex items-center gap-2">
              <button
                onClick={expand}
                className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
              >
                <IoExpandOutline className="text-lg" />
              </button>
              <button
                onClick={close}
                className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            id="chatbox"
            className="p-4 h-[70vh] sm:h-96 overflow-y-auto flex flex-col"
            ref={chatboxRef}
          >
            {!!messages &&
              messages.length > 0 &&
              messages.map((message) => {
                return (
                  <>
                    {message.user_id !== user.id ? (
                      <div className="mb-2 me-10">
                        <p className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-50 rounded-2xl rounded-bl-none py-2 px-4 inline-block">
                          {message.actual_msg}
                          <div className="text-gray-500 dark:text-gray-400 text-xs mt-2">
                            {/* <TimeAgo date={message.createdAt} /> */}
                            {dateFormat(new Date()) !==
                            dateFormat(message.createdAt)
                              ? dateFormat(message.createdAt)
                              : ""}{" "}
                            {timeFormat(message.createdAt)}
                          </div>
                        </p>
                      </div>
                    ) : (
                      <div className="mb-2 ms-10 self-end">
                        <p className="bg-blue-500 dark:bg-blue-900 text-white rounded-2xl rounded-br-none py-2 px-4 inline-block">
                          {message.actual_msg}
                          <div className="text-gray-300 dark:text-gray-400 text-right text-xs mt-2">
                            {/* <TimeAgo date={message.createdAt} /> */}
                            {dateFormat(new Date()) !==
                            dateFormat(message.createdAt)
                              ? dateFormat(message.createdAt)
                              : ""}{" "}
                            {timeFormat(message.createdAt)}
                          </div>
                        </p>
                      </div>
                    )}
                  </>
                );
              })}
          </div>
          <div className="p-4 border-t dark:bg-gray-800 flex rounded-b-lg gap-2 items-center">
            <textarea
              value={outMessage}
              onChange={(e) => setOutMessage(e.target.value)}
              type="text"
              placeholder="Type a message"
              className="w-full px-3 py-2 border rounded-lg dark:bg-gray-900 dark:text-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-900"
            />
            <div>
              <button
                onClick={handleSubmit}
                className="bg-blue-500 dark:bg-blue-900 text-white hover:bg-blue-600 dark:hover:bg-blue-950 p-2 py-4 w-12 flex items-center justify-center rounded-full transition duration-300"
              >
                <IoSend />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
