import TextArea from "../../../Components/TextArea";
import Select from "../../../Components/Select";
import InputError from "../../../Components/InputError";
import InputLabel from "../../../Components/InputLabel";
import TextInput from "../../../Components/TextInput";
import Modal from "../../../Components/Modal";
import { useState, useRef, useEffect } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import DangerButton from "../../../Components/DangerButton";
import { shops as shopUrl } from "../../../Data/apiRoutes";
import { toast } from "react-toastify";

export default ({
  user = null,
  token = null,
  show = false,
  shop = null,
  close = () => {},
  refresh = () => {},
}) => {
  const [selectedImage, setSelectedImage] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const imageRef = useRef(null);

  const [data, setData] = useState({
    name: shop?.name,
    is_registered: "",
    picture: null,
    attachment: null,
    description: shop?.name,
  });

  const [errors, setErrors] = useState({
    name: "",
    is_registered: "",
    picture: null,
    attachment: null,
    description: "",
  });

  const selectImage = () => {
    imageRef.current.click();
  };

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, [e.target.name]: e.target.files[0] });
      if (e.target.name == "picture") {
        setSelectedImage(e.target.files[0]);
      }
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const action = async () => {
    setIsProcessing((s) => !s);
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("photo", selectedImage);
      formData.append("user_id", user.id);

      const response = await fetch(`${shopUrl}/${shop?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "PATCH",
        body: formData,
      });
      const output = await response.json();
      console.log(output);
      setData({
        name: "",
        is_registered: "",
        picture: null,
        attachment: null,
        description: "",
      });
      setSelectedImage(null);
      refresh();
      close();
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
    setIsProcessing((s) => !s);
  };

  const deleteAction = async () => {
    if (
      window.confirm(`Do you really want to delete the shop: ${shop?.name}`)
    ) {
      try {
        const response = await fetch(`${shopUrl}/${shop?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "DELETE",
        });
        const resData = await response.json();
        refresh();
        close();
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    }
  };

  useEffect(
    () =>
      setData({
        name: shop?.name,
        is_registered: "",
        picture: null,
        attachment: null,
        description: shop?.name,
      }),
    [shop]
  );

  return (
    <Modal
      maxWidth="max-w-lg w-full"
      title="Update Shop/Business"
      show={show}
      close={close}
      customOk={
        <div className="w-full flex justify-between">
          <DangerButton disabled={isProcessing} onClick={deleteAction}>
            Delete
          </DangerButton>
          <PrimaryButton disabled={isProcessing} onClick={action}>
            Save
          </PrimaryButton>
        </div>
      }
    >
      <div className="md:grid grid-cols-2 gap-4">
        <div className="flex justify-center col-span-2">
          <div className="w-[150px] h-[150px] relative">
            <TextInput
              id="picture"
              name="picture"
              type="file"
              accept="image/jpeg,image/jpg,image/png"
              className="hidden"
              ref={imageRef}
              onChange={onChange}
            />
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : shop?.picture
                  ? `${
                      process.env.REACT_APP_BACKEND_URL
                    }/${shop?.picture?.replace(/"/g, "")}`
                  : "/img/image_icon1.png"
              }
              className="object-cover h-full"
              alt="shop"
            />
            <div
              onClick={selectImage}
              className="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
            >
              <div>Select Image</div>
            </div>

            <div
              className="cursor-pointer absolute z-40 bottom-1 right-1 bg-blue-500 p-1 rounded"
              onClick={selectImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="fill-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <InputLabel htmlFor="name" value="Shop Name *" />

          <TextInput
            id="name"
            name="name"
            className="mt-1 block w-full"
            value={data.name}
            onChange={onChange}
          />

          <InputError message={errors?.name} className="mt-2 capitalize" />
        </div>

        {/* <div>
          <InputLabel htmlFor="is_registered" value="Is Registered? *" />

          <Select
            name="is_registered"
            options={["True", "False"]}
            onChange={onChange}
            placeholder="Select an option"
          />

          <InputError
            message={errors?.is_registered}
            className="mt-2 capitalize"
          />
        </div> */}

        <div className="col-span-2">
          <InputLabel htmlFor="description" value="Shop Description" />

          <TextArea
            id="description"
            name="description"
            className="mt-1 block w-full"
            value={data.description}
            onChange={onChange}
          />

          <InputError
            message={errors?.description}
            className="mt-2 capitalize"
          />
        </div>
      </div>
    </Modal>
  );
};
