export default function TextArea({
  type = "text",
  className = "",
  isFocused = false,
  ...props
}) {
  return (
    <div className="flex flex-col items-start">
      <textarea
        {...props}
        type={type}
        className={
          "border-gray-300 border dark:border-gray-700 bg-gray-100 dark:bg-gray-900 dark:text-gray-300 focus:border-blue-500 dark:focus:border-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600 rounded-md shadow-sm p-2 " +
          className
        }
      />
    </div>
  );
}
