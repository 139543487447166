import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  products,
  topDiscountProducts,
  bestSellerProducts,
  featuredProducts,
} from "../Data/apiRoutes";
import axios from "axios";

export const useMonthsDeals = () => {
  const fetchProducts = async () => {
    try {
      const response = await axios.get(products);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch products");
    }
  };
  return useQuery({ queryKey: ["month_deals"], queryFn: fetchProducts });
};

export const useTopDiscounts = () => {
  const fetchProducts = async () => {
    try {
      const response = await axios.get(topDiscountProducts);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch products");
    }
  };
  return useQuery({
    queryKey: ["topDiscountProducts"],
    queryFn: fetchProducts,
  });
};

export const useBestSeller = () => {
  const fetchProducts = async () => {
    try {
      const response = await axios.get(bestSellerProducts);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch products");
    }
  };

  return useQuery({
    queryKey: ["bestSellerProducts"],
    queryFn: fetchProducts,
  });
};

export const useFeaturedProducts = () => {
  const fetchProducts = async () => {
    try {
      const response = await axios.get(featuredProducts);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch products");
    }
  };

  return useQuery({
    queryKey: ["featuredProducts"],
    queryFn: fetchProducts,
  });
};
