"use client";
import Container from "../../../Components/Container";
// import RelatedProduct from "../../../Components/RelatedProduct";
import ToCartButton from "../../../Components/ToCartButton";
import ToWishlistButton from "../../../Components/ToWishlistButton";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import BuyNowButton from "../../../Components/BuyNowButton";
import InquiryButton from "../../../Components/InquiryButton";
import { setVariantsInCart, setCart } from "../../../Context/reducer";
import { useDispatch } from "react-redux";
import BuyNowPopup from "../../../Widgets/BuyNowPopup";
import ReactGA from "react-ga";
import { toast } from "react-toastify";

const mergeArrays = (a, b, predicate = (a, b) => a === b) => {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) =>
    c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)
  );
  return c;
};

export default ({ product, user, images }) => {
  const dispatch = useDispatch();
  const [variants, setVariants] = useState(null);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [activeSizes, setActiveSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [showBuyNow, setShowBuyNow] = useState(false);

  const handleColor = (event, colors) => {
    setSelectedColors(colors);
    setActiveSizes(variants[colors]);
    setSelectedSizes([]);
  };

  const handleSize = (event, sizes) => {
    setSelectedSizes(sizes);
  };

  useEffect(() => {
    const vars = {};
    var newSizes = [];
    setColors([]);
    for (let index = 0; index < product?.ProductVariants?.length; index++) {
      const variant = product?.ProductVariants[index];
      if (!vars[variant.color]) {
        vars[variant.color] = [];
        setColors((state) => [...state, variant.color]);
      }
      vars[variant.color].push(variant.size);

      if (!sizes.includes(variant.size)) {
        newSizes.push(variant.size);
      }
    }

    setSizes(
      newSizes.filter(function (item, pos) {
        return newSizes.indexOf(item) == pos;
      })
    );
    setVariants(vars);
  }, [product]);

  const addToCart = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
      category: "Users",
      action: `product_to_cart`,
      label: `Add to Cart`,
      value: product.id,
    });

    if (colors.length > 0) {
      let myvariants = [];
      for (let index = 0; index < product?.ProductVariants?.length; index++) {
        const element = product?.ProductVariants[index];
        if (selectedColors === element.color) {
          myvariants.push(element);
        }
      }

      if (sizes.length > 0) {
        let variants_2 = [];
        for (let index = 0; index < myvariants.length; index++) {
          const element = myvariants[index];
          if (selectedSizes.includes(element.size)) {
            variants_2.push(element);
          }
        }
        const cart_products = variants_2.map((variant) => {
          return {
            product_id: product.id,
            variant_id: variant.id,
            name: product.name,
            unit_price: product.price,
            discount: product.discount,
            quantity: 1,
            picture: variant.picture,
            color: variant.color,
            size: variant.size,
            max: variant.quantity,
          };
        });
        dispatch(setVariantsInCart(variants_2));
        dispatch(setCart(cart_products));
        toast.success("Product added to cart.");
      }
    } else {
      const cart_products = [
        {
          product_id: product.id,
          variant_id: null,
          name: product.name,
          unit_price: product.price,
          discount: product.discount,
          quantity: 1,
          picture: product.picture,
          color: null,
          size: null,
          max: 1,
        },
      ];
      dispatch(setCart(cart_products));
      toast.success("Product added to cart.");
    }
  };

  return (
    <>
      <Container>
        {showBuyNow && (
          <BuyNowPopup
            show={showBuyNow}
            close={() => setShowBuyNow((s) => !s)}
            product={product}
          />
        )}
        <div className="text-lg md:text-xl md:flex justify-between font-bold border-b py-2">
          <div>{product?.name}</div>
          <div className="md:ms-2 inline-block md:bg-blue-500 md:py-1 md:px-2 rounded-full text-sm">
            MWK{" "}
            {product?.price?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
        <div className="md:grid grid-cols-3 gap-4">
          <div className="w-full shadow mb-2">
            {images.length > 0 && (
              <ImageGallery showPlayButton={false} items={images} />
            )}
          </div>

          <div className="col-span-2">
            <p className="text-justify text-sm text-gray-700 dark:text-gray-300 my-2">
              {product?.description}
            </p>
            <p>
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                Features:
              </h2>
              <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400 text-sm">
                <li>Great Sound</li>
                <li>Good Music</li>
                <li>Comfort</li>
              </ul>
            </p>

            {colors.length > 0 && (
              <div className="my-2">
                Color:
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={selectedColors}
                  onChange={handleColor}
                  aria-label="colors"
                >
                  {colors.map((color, index) => (
                    <ToggleButton
                      value={color}
                      aria-label={color}
                      key={index}
                      sx={{ p: 0 }}
                    >
                      <div
                        className={`text-gray-800 dark:text-gray-300 px-3 py-2 rounded ${
                          selectedColors == color //selectedColors.includes(color)
                            ? "bg-blue-300 dark:bg-blue-800"
                            : "bg-slate-200 dark:bg-slate-700 hover:bg-slate-300 dark:hover:bg-slate-600"
                        }`}
                      >
                        {color}
                      </div>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}

            {sizes.length > 0 && (
              <div className="my-2">
                Sizes:
                <ToggleButtonGroup
                  value={selectedSizes}
                  onChange={handleSize}
                  aria-label="sizes"
                >
                  {sizes.map((size, index) => (
                    <ToggleButton
                      value={size}
                      aria-label={size}
                      disabled={
                        activeSizes ? !activeSizes.includes(size) : false
                      }
                      key={index}
                      sx={{ p: 0 }}
                    >
                      <div
                        className={`text-gray-800 dark:text-gray-300 px-3 py-2 rounded ${
                          activeSizes
                            ? activeSizes.includes(size)
                              ? selectedSizes.includes(size)
                                ? "bg-blue-300 dark:bg-blue-800"
                                : "bg-slate-200 dark:bg-slate-700 hover:bg-slate-300 dark:hover-bg-slate-600"
                              : "bg-gray-500 text-gray-700 opacity-50"
                            : "bg-gray-500 text-gray-700 opacity-50"
                        }`}
                      >
                        {size}
                      </div>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            )}

            <div className="my-2 md:flex justify-end">
              <div className="hidden md:flex gap-2">
                <BuyNowButton onClick={() => setShowBuyNow((s) => !s)} />
                <ToCartButton onClick={addToCart} />
                {user && (
                  <>
                    <ToWishlistButton product={product} user={user} />
                    <InquiryButton user={product.User} product={product} />{" "}
                  </>
                )}
              </div>
              <div className="flex gap-2 md:hidden">
                <BuyNowButton
                  hideText
                  onClick={() => setShowBuyNow((s) => !s)}
                />
                <ToCartButton onClick={addToCart} hideText />
                {user && (
                  <>
                    <ToWishlistButton hideText product={product} user={user} />
                    <InquiryButton user={product.User} product={product} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="text-xl text-gray-900 dark:text-gray-100 text-center font-semibold">
        Related Products
      </div>
      <Container>
        <div className="grid grid-cols-5 gap-3">
          {/* {[1, 2, 3, 4, 5].map((num) => (
            <RelatedProduct />
          ))} */}
        </div>
      </Container>
    </>
  );
};
