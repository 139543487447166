import ProductCard from "../Components/HomeProductCard";
import Container from "../Components/Container";

export default ({ title = "", products = [] }) => {
  return (
    <Container transparent padding="" xpadding="">
      <div>
        <div className="text-center font-bold text-2xl">{title}</div>
        <div className="flex justify-center">
          <div className="bg-blue-500 h-1 w-16"></div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 py-4">
          {!!products &&
            products?.length > 0 &&
            products.map((product) => (
              <ProductCard product={product} key={product.id} />
            ))}
        </div>
      </div>
    </Container>
  );
};
