import Container from "../../../Components/Container";
import { Link } from "react-router-dom";
import EditIcon from "../../../Assets/JSX/EditIcon";
import { numberFormat } from "../../../Functions/functions";
import UpdateProduct from "../Modal/UpdateProduct";
import { useState } from "react";
import UpdateProductVariants from "../Modal/UpdateProductVariants";
import { useProductUpdate } from "../../../Hooks/useProductUpdate";

export default function SellerShowShop({
  products,
  categories,
  token,
  user,
  refresh,
}) {
  const [processing, setProcessing] = useState(false);
  const [product, setProduct] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [removedVariants, setRemovedVariants] = useState([]);
  const [productModal, setIsProductModal] = useState(false);
  const [productVariantModal, setIsProductVariantModal] = useState(false);

  const { shops, saveProduct, update, back } = useProductUpdate({
    user, 
    token,
    product,
    productVariants,
    removedVariants,
    setProduct,
    setIsProductModal,
    setIsProductVariantModal,
    setProductVariants,
    setRemovedVariants,
    refresh,
    setProcessing,
  });

  return (
    <Container>
      {productModal && (
        <UpdateProduct
          show={productModal}
          product={product}
          setProduct={setProduct}
          categories={categories}
          close={() => setIsProductModal((s) => !s)}
          setIsProductVariantModal={setIsProductVariantModal}
          shops={shops}
        />
      )}

      {productVariantModal && (
        <UpdateProductVariants
          show={productVariantModal}
          productVariants={productVariants}
          setProductVariants={setProductVariants}
          back={back}
          close={() => setIsProductVariantModal((s) => !s)}
          save={saveProduct}
          processing={processing}
          setRemovedVariants={setRemovedVariants}
        />
      )}

      <div className="grid grid-cols-4 md:grid-cols-6">
        <div></div>
        <div className="font-bold col-start-2 col-end-4">Product Name</div>
        <div className="font-bold col-start-4 col-end-6 text-end">Price</div>
        <div className="font-bold text-center">View/Update</div>
      </div>
      {!!products ? (
        products.map((row, index) => (
          <div
            key={`shop-${row.id}`}
            className="grid grid-cols-4 md:grid-cols-6 gap-2 p-2 items-center border border-gray-300 dark:border-gray-700 mb-2 rounded shadow hover:bg-gray-900 transition-all"
          >
            <Link to={`/products/${row.id}`}>
              <img
                src={
                  row?.picture
                    ? `${
                        process.env.REACT_APP_BACKEND_URL
                      }/${row?.picture?.replace(/"/g, "")}`
                    : "/img/image_icon1.png"
                }
                width={60}
                height={60}
                className="bg-white rounded object-cover"
              />
            </Link>
            <Link to={`/products/${row.id}`} className="col-start-2 col-end-4">
              {row?.name}
            </Link>
            <div className="col-start-4 col-end-6 hidden md:inline-block text-end">
              {numberFormat(row?.price)}
            </div>
            <div className="">
              <div className="flex justify-center gap-5">
                <button onClick={() => update(row)}>
                  <EditIcon className="fill-gray-900 dark:fill-gray-200" />
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>Loading...</>
      )}
    </Container>
  );
}
