import { useState, useEffect } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { useQuery } from "../../Hooks/useQuery";
import Container from "../../Components/Container";
import { useSearchParams, Link } from "react-router-dom";
import SearchWidget from "../../Widgets/SearchWidget";
import axios from "axios";
import { searchProducts, searchShops } from "../../Data/apiRoutes";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function tabSpan(name, count) {
  return (
    <div>
      {name}{" "}
      <span className="ms-1 bg-gray-500 text-white px-1 rounded-3xl text-sm">
        {count}
      </span>
    </div>
  );
}

const ResultItem = ({ item, type }) => (
  <Link
    key={`${type}-${item.id}`}
    to={`/${type}/${item.id}`}
    className="flex gap-2 p-2 dark:hover:bg-gray-700 rounded cursor-pointer"
  >
    <div className="w-10 h-10">
      <img
        src={
          item?.picture
            ? `${process.env.REACT_APP_BACKEND_URL}/${item?.picture
                ?.replace('"', "")
                .replace('"', "")}`
            : "/img/image_icon1.png"
        }
        className="w-10 h-10 object-cover"
      />
    </div>
    <div>
      <div className="font-bold">{item.name}</div>
      <div className="text-xs text-gray-500">
        {type === "products" && `${item.Shop.name} - `}
        {item.User.name}
      </div>
    </div>
  </Link>
);

export default () => {
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(query.get("q"));

  const [products, setProducts] = useState([]);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    searchParams.set("q", value);
    setSearchParams(searchParams);
  }, [value, searchParams, setSearchParams]);

  const fetchData = async () => {
    try {
      const [productsResponse, shopResponse] = await Promise.all([
        axios.get(`${searchProducts}?q=${value}`),
        axios.get(`${searchShops}?q=${value}`),
      ]);

      setProducts(productsResponse.data);
      setShops(shopResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      // Perform the searching here
      fetchData();
    }, 500); //delay action by 500ms
    return () => clearTimeout(handler);
  }, [value]);

  return (
    <MainLayout>
      <div className="mt-16">
        <SearchWidget value={value} setValue={setValue} />
      </div>
      <Container>
        <Tab.Group>
          <Tab.List className="border-b border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400">
            {["All", "Products", "Shops"].map((tab) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "outline-none transition-all p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300",
                    selected
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : ""
                  )
                }
              >
                {tab === "All"
                  ? tabSpan(`All`, `${products.length + shops.length}`)
                  : tab === "Products"
                  ? tabSpan(`Products`, `${products.length}`)
                  : tabSpan(`Shops`, `${shops.length}`)}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className={"pt-2"}>
            <Tab.Panel>
              {/* All Results */}
              <div className="sm:grid grid-cols-2 gap-3">
                {products.map((product) => (
                  <ResultItem item={product} type={"products"} />
                ))}
                {shops.map((shop) => (
                  <ResultItem item={shop} type={"shops"} />
                ))}
              </div>
              {products.length + shops.length === 0 && (
                <div className="my-20 text-center">No Results!</div>
              )}
            </Tab.Panel>
            <Tab.Panel>
              {/* Products Results */}
              <div className="sm:grid grid-cols-2 gap-3">
                {products.map((product) => (
                  <ResultItem item={product} type={"products"} />
                ))}
              </div>
              {products.length === 0 && (
                <div className="my-20 text-center">No Results!</div>
              )}
            </Tab.Panel>
            <Tab.Panel>
              {/* Shops Results */}
              <div className="sm:grid grid-cols-2 gap-3">
                {shops.map((shop) => (
                  <ResultItem item={shop} type={"shops"} />
                ))}
              </div>
              {shops.length === 0 && (
                <div className="my-20 text-center">No Results!</div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Container>
    </MainLayout>
  );
};
