import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import { useEffect, useState } from "react";
import Datatable from "../../../Components/Datatable";
import { Link } from "react-router-dom";
import TrashIcon from "../../../Assets/JSX/TrashIcon";
import EditIcon from "../../../Assets/JSX/EditIcon";
import NewCategory from "./NewCategory";
import { useDispatch, useSelector } from "react-redux";
import { setCategories } from "../../../Context/reducer";
import { categories as allCategories } from "../../../Data/apiRoutes";
import { dateFormat } from "../../../Functions/functions";
import UpdateCategory from "./UpdateCategory";

export default function AdminCategories({ user }) {
  const dispatch = useDispatch();
  const [showAddCategory, setShowCategory] = useState(false);
  const token = useSelector((state) => state.token);
  const categories = useSelector((state) => state.categories);
  const [activeCategory, setActiveCategory] = useState({});
  const [showEditCategory, setShowEditCategory] = useState(false);

  const getCategories = async () => {
    const response = await fetch(allCategories, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setCategories({ categories: data.categories }));
  };

  const editCategory = (index) => {
    setActiveCategory(categories[index]);
    setShowEditCategory((s) => !s);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Container>
      <NewCategory
        token={token}
        show={showAddCategory}
        close={() => setShowCategory((s) => !s)}
        refresh={getCategories}
      />
      <UpdateCategory
        token={token}
        show={showEditCategory}
        close={() => setShowEditCategory((s) => !s)}
        category={activeCategory}
        refresh={getCategories}
      />
      <div className="mb-3 flex justify-between">
        <div></div>
        <PrimaryButton onClick={() => setShowCategory((s) => !s)}>
          Add Category
        </PrimaryButton>
      </div>

      {categories ? (
        <Datatable>
          <table
            className={
              "data-table w-full text-sm text-left text-gray-500 dark:text-gray-400 "
            }
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-2">
                  &nbsp;
                </th>
                <th scope="col" className="px-4 py-2">
                  Shop Name
                </th>
                <th scope="col" className="px-4 py-2">
                  Created At
                </th>
                <th scope="col" className="px-4 py-2">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map((row, index) => (
                <tr
                  key={`${index}`}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-4 py-2 text-gray-900 dark:text-white justify-center">
                    <img
                      src={
                        row?.picture
                          ? `${
                              process.env.REACT_APP_BACKEND_URL
                            }/${row?.picture?.replace(/"/g, "")}`
                          : "/img/image_icon1.png"
                      }
                      width={50}
                      height={50}
                      className="bg-white rounded object-cover"
                    />
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white underline underline-offset-2">
                    <Link to={`/categories/${row.id}`}>{row.name}</Link>
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    {dateFormat(row?.createdAt)}
                  </td>
                  <td className="px-4 py-2 text-gray-900 dark:text-white">
                    <div className="flex justify-center gap-5">
                      <button onClick={() => editCategory(index)}>
                        <EditIcon className="fill-gray-900 dark:fill-gray-200" />
                      </button>
                      <button>
                        <TrashIcon className="fill-gray-900 dark:fill-gray-200" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Datatable>
      ) : (
        <>Loading...</>
      )}
    </Container>
  );
}
