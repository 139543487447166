import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  categories: [],
  shops: [],
  cart: [],
  variantsInCart: [],
  buynow: {},
  isMessenger: false,
  recipient: null,
  templateMessage: "",
  subscriptionTypes: [],
  selectedSubscriptionType: null,
  tempData: null,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
    setCategories: (state, action) => {
      state.categories = action.payload.categories;
    },
    setShops: (state, action) => {
      state.shops = action.payload;
    },
    setIsMessenger: (state, action) => {
      state.isMessenger = action.payload;
    },
    setRecipient: (state, action) => {
      state.recipient = action.payload;
    },
    removeRecipient: (state) => {
      state.recipient = null;
    },
    setVariantsInCart: (state, action) => {
      const allIds = state.variantsInCart.map((obj) => {
        return obj.id;
      });

      const vars = action.payload.filter((variant) => {
        return !allIds.includes(variant.id);
      });

      state.variantsInCart = [...state.variantsInCart, ...vars];
    },
    setBuyNow: (state, action) => {
      state.buynow = action.payload;
    },
    setCart: (state, action) => {
      const allIds = state.cart.map((obj) => {
        return obj.variant_id;
      });

      const vars = action.payload.filter((product) => {
        return !allIds.includes(product.variant_id);
      });

      state.cart = [...state.cart, ...vars];
    },
    incrementCartQuantity: (state, action) => {
      const out = {
        ...action.payload,
        quantity:
          action.payload.quantity + 1 <= action.payload.max
            ? action.payload.quantity + 1
            : action.payload.quantity,
      };

      state.cart = state.cart.map((item) => {
        if (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        ) {
          return { ...out };
        } else {
          return { ...item };
        }
      });
    },
    decrementCartQuantity: (state, action) => {
      const out = {
        ...action.payload,
        quantity:
          action.payload.quantity - 1 >= 1 ? action.payload.quantity - 1 : 1,
      };

      state.cart = state.cart.map((item) => {
        if (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        ) {
          return { ...out };
        } else {
          return { ...item };
        }
      });
    },
    removeFromCart: (state, action) => {
      const item = state.cart.find((item) => {
        if (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        )
          return true;
      });
      state.cart = state.cart.filter((s) => s != item);
      state.variantsInCart = state.variantsInCart.filter(
        (s) => s.id != item.variant_id
      );
    },
    clearCart: (state) => {
      state.variantsInCart = [];
      state.cart = [];
    },
    clearBuyNow: (state) => {
      state.buynow = null;
    },
    setTemplateMessage: (state, action) => {
      state.templateMessage = action.payload;
    },
    setSubscriptionTypes: (state, action) => {
      state.subscriptionTypes = action.payload;
    },
    setselectedSubscriptionType: (state, action) => {
      state.selectedSubscriptionType = action.payload;
    },
    setTempData: (state, action) => {
      state.tempData = action.payload;
    },
  },
});

export const {
  setUser,
  setLogin,
  setLogout,
  setCategories,
  setShops,
  setIsMessenger,
  setRecipient,
  removeRecipient,
  setVariantsInCart,
  setCart,
  clearCart,
  clearBuyNow,
  removeFromCart,
  incrementCartQuantity,
  decrementCartQuantity,
  setBuyNow,
  setTemplateMessage,
  setSubscriptionTypes,
  setselectedSubscriptionType,
  setTempData
} = mainSlice.actions;
export default mainSlice.reducer;
