import Modal from "../../../Components/Modal";
import Select from "../../../Components/Select";
import TextInput from "../../../Components/TextInput";
import InputError from "../../../Components/InputError";
import InputLabel from "../../../Components/InputLabel";
import { useForm } from "../../../Hooks/useForm";
import { useEffect, useState } from "react";
import { payments as paymentUrl } from "../../../Data/apiRoutes";
import axios from "axios";
import { toast } from "react-toastify";

export default ({ order, token, show, close, fetchOrder }) => {
  const { values, handleChange } = useForm({
    msisdn: "265",
    method: undefined,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log(values);
  }, [values]);

  const submit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        msisdn: values.msisdn,
        subtotal: order.subtotal,
        order_id: order.id,
        user_id: order.User.id,
      };

      const response = await axios.post(`${paymentUrl}/retry`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 201) {
        toast.success("Payment success");
        close();
        fetchOrder();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} close={close} okAction={submit}>
      <form onSubmit={submit}>
        <div className="my-3">
          <InputLabel htmlFor="method" value="Payment Method" />

          <Select
            id="method"
            name="method"
            options={["Airtel Money"]}
            className="mt-1 block w-full"
            value={values?.method}
            onChange={handleChange}
          />

          <InputError message={errors?.method} className="mt-2" />
        </div>

        {values?.method && (
          <div className="my-3">
            <InputLabel htmlFor="msisdn" value="Phone Number" />

            <TextInput
              id="msisdn"
              type="text"
              name="msisdn"
              value={values?.msisdn}
              className="mt-1 block w-full"
              onChange={handleChange}
            />

            <InputError message={errors?.msisdn} className="mt-2" />
          </div>
        )}
      </form>
    </Modal>
  );
};
