import { forwardRef, useEffect, useRef } from "react";

export default forwardRef(function TextInput(
  { type = "text", className = "", isFocused = false, ...props },
  ref
) {
  const input = ref;

  useEffect(() => {
    if (isFocused) {
      input.current?.focus();
    }
  }, []);

  return (
    <div className="flex flex-col items-start">
      <input
        {...props}
        type={type}
        className={
          `p-2 border border-gray-300 dark:border-gray-700 ${
            props.disabled ? "bg-gray-100 dark:bg-gray-800" : "dark:bg-gray-900"
          } dark:text-gray-300 focus:border-blue-500 dark:focus:border-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600 rounded-md shadow-sm ` +
          className
        }
        ref={input}
      />
    </div>
  );
});
