"use client";

import Modal from "../../../Components/Modal";
import TextInput from "../../../Components/TextInput";
import { useState, useRef } from "react";
import PrimaryButton from "../../../Components/PrimaryButton";
import AddIcon from "../../../Assets/JSX/AddIcon";
import CrossIcon from "../../../Assets/JSX/CrossIcon";
import SecondaryButton from "../../../Components/SecondaryButton";
import { isString } from "../../../Functions/functions";

export default ({
  show,
  productVariants,
  setProductVariants,
  back = () => {},
  close,
  save,
  processing,
  setRemovedVariants,
}) => {
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState({
    picture: undefined,
    color: "",
    size: "",
    quantity: 0,
  });

  const selectImage = () => {
    imageRef.current.click();
  };

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, [e.target.name]: e.target.files[0] });
      setSelectedImage(e.target.files[0]);
      imageRef.current.value = "";
      return;
    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const addVariant = () => {
    if (data.picture && data.color && data.size && data.quantity) {
      setProductVariants((prevState) => [
        ...prevState,
        {
          ...data,
          picture: selectedImage,
        },
      ]);
      setData({
        picture: null,
        color: "",
        size: "",
        quantity: 0,
      });
      setSelectedImage(null);
    } else {
      alert("All fields are required!");
    }
  };

  const removeVariant = (variant) => {
    setRemovedVariants(state => [
      ...state,
      variant
    ])
    setProductVariants((state) => {
      return state.filter((el) => {
        return el != variant;
      });
    });
  };

  return (
    <Modal
      show={show}
      title="Update Product Variants"
      close={close}
      customOk={
        <>
          <SecondaryButton disabled={processing} onClick={back}>
            Back
          </SecondaryButton>
          <PrimaryButton disabled={processing} onClick={save}>
            Save
          </PrimaryButton>
        </>
      }
      maxWidth="max-7xl sm:max-w-3xl"
    >
      <table
        className={"w-full text-sm text-left text-gray-500 dark:text-gray-400 "}
      >
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-4 py-2">
              Image
            </th>
            <th scope="col" className="px-4 py-2">
              Color
            </th>
            <th scope="col" className="px-4 py-2">
              Size
            </th>
            <th scope="col" className="px-4 py-2">
              Quantity
            </th>
            <th scope="col" className="px-4 py-2">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          {!!productVariants &&
            productVariants.map((variant, index) => (
              <tr key={index}>
                <td className="px-4 py-2 text-gray-900 dark:text-white">
                  <div className="w-[50px] h-[50px] relative overflow-hidden">
                    <img
                      src={
                        isString(variant.picture)
                          ? `${
                              process.env.REACT_APP_BACKEND_URL
                            }/${variant?.picture?.replace(/"/g, "")}`
                          : URL.createObjectURL(variant.picture)
                      }
                      className="w-[50px] h-[50px] object-cover"
                      alt="variant"
                    />
                  </div>
                </td>
                <td className="px-4 py-2 text-gray-900 dark:text-white">
                  {variant.color}
                </td>
                <td className="px-4 py-2 text-gray-900 dark:text-white">
                  {variant.size}
                </td>
                <td className="px-4 py-2 text-gray-900 dark:text-white">
                  {variant.quantity}
                </td>
                <td className="px-4 py-2 text-gray-900 dark:text-white">
                  <div
                    onClick={() => removeVariant(variant)}
                    className="cursor-pointer flex justify-end"
                  >
                    <CrossIcon className="fill-black dark:fill-white" />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className="font-bold dark:text-white my-2">Add Variant</div>
      <div className="sm:flex items-center">
        <div className="sm:flex justify-center">
          <div className="flex gap-2">
            <div className="w-[50px] h-[50px] relative">
              <TextInput
                id="picture"
                name="picture"
                type="file"
                accept="image/jpeg,image/jpg,image/png"
                className="hidden"
                ref={imageRef}
                onChange={onChange}
              />
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : "/img/image_icon2.png"
                }
                className="w-[50px] h-[50px] object-cover"
                alt="shop photo"
              />
              <div
                onClick={selectImage}
                class="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
              ></div>

              <div
                className="cursor-pointer absolute z-40 bottom-0 right-0 bg-blue-500 p-0 rounded"
                onClick={selectImage}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-white"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                  <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
                </svg>
              </div>
            </div>
            <div className="px-2">
              <TextInput
                id="color"
                name="color"
                className="mt-1 block w-full"
                placeholder="Color"
                value={data.color}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="px-2">
              <TextInput
                id="size"
                name="size"
                className="mt-1 block w-full"
                placeholder="Size"
                value={data.size}
                onChange={onChange}
              />
            </div>
            <div className="flex px-2">
              <TextInput
                id="quantity"
                name="quantity"
                type="number"
                min="0"
                className="mt-1 block w-full"
                placeholder="Quantity"
                value={data.quantity}
                onChange={onChange}
              />
            </div>
            <button className="px-2 cursor-pointer" onClick={addVariant}>
              <AddIcon className="fill-black dark:fill-white" />
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
