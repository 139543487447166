import { useState, useEffect } from "react";
import AppLogo from "../../Components/AppLogo";
import Container from "../../Components/Container";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import MainLayout from "../../Layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyEmail as verifyUrl,
  sendEmailOTP as emailOTPUrl,
  users as userUrl,
} from "../../Data/apiRoutes";
import Spin from "../../Assets/JSX/Spin";
import { setUser } from "../../Context/reducer";
import { useNavigate } from "react-router-dom";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((s) => s.user);
  const token = useSelector((s) => s.token);
  const [otp, setOtp] = useState("");
  const [processing, setProcessing] = useState(false);
  const [counter, setCounter] = useState(0);

  const onChangeHandler = (e) => {
    if (isNaN(e.target.value.replace(/\s/g, ""))) return;
    else {
      let value = e.target.value;

      // Remove any existing spaces
      value = value.replace(/\s/g, "");

      var len = value.length;

      // Add a space after every character
      value = value.split("").join("   ");

      if (len <= 5) {
        setOtp(value);
      }
    }
  };

  const resendOTP = async () => {
    setProcessing(true);
    if (!!user?.email) {
      try {
        const body = {
          email: user.email,
        };
        const response = await fetch(emailOTPUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        setCounter(60);
      } catch (err) {
        console.log(err);
      }
    }
    setProcessing(false);
  };

  const verifyEmail = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await fetch(verifyUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: user?.email,
          code: otp.replace(/\s/g, ""),
        }),
      });
      const data = await response.json();
      if (data.status === true) {
        const res = await fetch(`${userUrl}/${user.id}`);
        const userData = await res.json();
        dispatch(setUser(userData));
      }
    } catch (err) {
      console.log(err);
    }
    setProcessing(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(timer); // Clear the interval when counter reaches 0
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [counter]);

  useEffect(() => {
    navigate("/");
  }, [user]);

  return (
    <MainLayout>
      <div className="flex justify-center items-center min-h-[70vh] text-center">
        <Container size="md:min-w-[500px]">
          <form onSubmit={verifyEmail}>
            <div className="flex justify-center items-center">
              <div className="bg-gray-300 dark:bg-gray-700 p-4 rounded-full">
                <AppLogo className="w-10 h-10 object-cover" />
              </div>
            </div>

            <div className="text-2xl font-bold text-center my-3">
              Email Verification
            </div>

            <div>
              Enter the 5 digit code that was sent to your email address:
              <div className="text-blue-500">{user?.email}</div>
            </div>

            <TextInput
              className="w-full my-3 text-center"
              value={otp}
              onChange={onChangeHandler}
            />

            <PrimaryButton
              className="w-full flex justify-center"
              disabled={otp.replace(/\s/g, "").length < 5 || processing}
              onClick={verifyEmail}
            >
              {processing && <Spin />}
              Verify
            </PrimaryButton>

            <div className="flex justify-center pt-5">
              {processing && <Spin />}
              {!processing && counter === 0 && (
                <div
                  className="text-sm text-gray-600 dark:text-gray-400 hover:underline cursor-pointer"
                  onClick={resendOTP}
                >
                  Resend OTP?
                </div>
              )}
              {counter > 0 && (
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  Wait {counter} seconds to send OTP.
                </div>
              )}
            </div>
          </form>
        </Container>
      </div>
    </MainLayout>
  );
};
