import { useDispatch } from "react-redux";
import SecondaryButton from "./SecondaryButton";
import { TbMessage2Question } from "react-icons/tb";
import {
  setIsMessenger,
  setRecipient,
  setTemplateMessage,
} from "../Context/reducer";

export default (props) => {
  const dispatch = useDispatch();
  const handleAction = () => {
    dispatch(setRecipient(props.user));
    dispatch(setIsMessenger(true));
    dispatch(
      setTemplateMessage(
        `Hello\nI would like to know more about the product: ${props?.product?.name}`
      )
    );
  };
  return (
    <SecondaryButton {...props} onClick={handleAction}>
      <TbMessage2Question className="text-xl" />
      {!props.hideText && <span className="ms-1">Inquiry</span>}
    </SecondaryButton>
  );
};
