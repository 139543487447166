import Container from "./Container";
import {
  FaMoneyBill,
  FaArrowUp,
  FaArrowDown,
  FaPlus,
  FaMinus,
} from "react-icons/fa";

export default ({ title, content, percentage, increment, status }) => {
  return (
    <Container ypadding="" xpadding="" size="w-full">
      <div className="flex gap-4 items-center">
        <div className="p-2 border rounded-full">
          <FaMoneyBill className="text-xl" />
        </div>
        <div>{title}</div>
      </div>
      <div className="font-bold text-2xl my-3">{content}</div>
      <div className="flex gap-8 items-center text-sm">
        <div className="flex gap-1 items-center">
          {status === "more" ? (
            <FaArrowUp className="text-green-500" />
          ) : status === "less" ? (
            <FaArrowDown className="text-red-500" />
          ) : (
            <FaMinus className="text-gray-500" />
          )}
          {percentage}%
        </div>
        <div className="flex gap-1 items-center">
          <FaPlus className="text-green-500" />
          {increment}
        </div>
      </div>
    </Container>
  );
};
