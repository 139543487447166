import Jumbotron from "./Jumbotron";
import ProductsWidget from "../../../Widgets/HomeProductsWidget";
import {
  useMonthsDeals,
  useTopDiscounts,
  useBestSeller,
  useFeaturedProducts,
} from "../../../Hooks/useProducts";
import Adverts from "./Adverts";
import AdvertCarousel from "./AdvertCarousel";

export default function CustomerHome() {
  const topDiscounts = useTopDiscounts();
  const bestSeller = useBestSeller();
  const featuredProducts = useFeaturedProducts();

  return (
    <>
      <Jumbotron />

      {!!bestSeller?.data && (
        <ProductsWidget
          title={
            <>
              <div className="font-black text-3xl">Best Seller</div>
              <div className="text-normal text-xs md:text-sm">Products</div>
            </>
          }
          products={bestSeller.data?.products}
        />
      )}
      <Adverts />
      {!!topDiscounts?.data && (
        <ProductsWidget
          title={
            <>
              <div className="font-black text-3xl">This Month's</div>
              <div className="text-normal text-xs md:text-sm">Deals</div>
            </>
          }
          products={topDiscounts.data?.products}
        />
      )}

      <AdvertCarousel />

      {!!featuredProducts?.data && (
        <ProductsWidget
          title={
            <>
              <div className="font-black text-3xl">Featured</div>
              <div className="text-normal text-xs md:text-sm">Products</div>
            </>
          }
          products={featuredProducts.data?.products}
        />
      )}
    </>
  );
}
