import { useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import AdminCategories from "./Admin/AdminCategories";

export default function Categories() {
  const user = useSelector((state) => state.user);
  return (
    <MainLayout
    //   header={
    //     user?.role === "seller" && (
    //       <h2 className="text-gray-900 dark:text-gray-200 font-xl py-4 font-bold">
    //         Manage Shops
    //       </h2>
    //     )
    //   }
    >
      {user?.role === "admin" ? <AdminCategories user={user} /> : <></>}
    </MainLayout>
  );
}
