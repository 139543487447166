import Modal from "../../../Components/Modal";
import InputError from "../../../Components/InputError";
import InputLabel from "../../../Components/InputLabel";
import TextInput from "../../../Components/TextInput";
import { useState, useEffect, useRef } from "react";
import TextArea from "../../../Components/TextArea";
import PrimaryButton from "../../../Components/PrimaryButton";
import Select from "../../../Components/Select";
import { toast } from "react-toastify";

export default (props) => {
  const { show, categories, setProduct, setIsNewproductVariantModal, close } =
    props;
  const imageRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState({
    picture: undefined,
    name: "",
    price: 0.0,
    discount: 0.0,
    is_featured: "",
    description: "",
    category: "",
  });

  const selectImage = () => {
    imageRef.current.click();
  };

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, [e.target.name]: e.target.files[0] });
      setSelectedImage(e.target.files[0]);
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const nextPage = () => {
    console.log(data);
    if (
      data.picture &&
      data.name &&
      data.price &&
      data.is_featured &&
      data.category
    ) {
      setProduct({
        ...data,
        picture: selectedImage,
      });
      close();
      setIsNewproductVariantModal((s) => !s);
    } else {
      toast.warning(
        "All fields are required except the Product Description which is optional!"
      );
    }
  };

  return (
    <Modal
      show={show}
      title="Add New Product"
      close={close}
      customOk={<PrimaryButton onClick={nextPage}>Next</PrimaryButton>}
      maxWidth="max-w-3xl"
    >
      <div className="sm:grid grid-cols-4 gap-2">
        <div className="flex justify-center">
          <div className="w-[150px] h-[150px] relative overflow-hidden">
            <TextInput
              id="picture"
              name="picture"
              type="file"
              accept="image/jpeg,image/jpg,image/png"
              className="hidden"
              ref={imageRef}
              onChange={onChange}
            />
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : "/img/image_icon1.png"
              }
              className="w-[150px] h-[150px] object-cover"
              alt="shop photo"
            />
            <div
              onClick={selectImage}
              className="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
            >
              <div>Select Image</div>
            </div>

            <div
              className="cursor-pointer absolute z-40 bottom-1 right-1 bg-blue-500 p-1 rounded"
              onClick={selectImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="fill-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
              </svg>
            </div>
          </div>
        </div>

        <div className="sm:grid grid-cols-6 col-span-3 gap-2">
          <div className="col-span-3">
            <InputLabel htmlFor="name" value="Product Name *" />

            <TextInput
              id="name"
              name="name"
              className="mt-1 block w-full"
              value={data.name}
              onChange={onChange}
            />

            <InputError
              // message={errors?.name}
              className="mt-2 capitalize"
            />
          </div>

          <div className="col-span-3">
            <InputLabel htmlFor="price" value="Product Price(MWK) *" />

            <TextInput
              id="price"
              name="price"
              type="number"
              step="0.01"
              className="mt-1 block w-full"
              value={data.price}
              onChange={onChange}
            />

            <InputError
              // message={errors?.price}
              className="mt-2 capitalize"
            />
          </div>

          <div className="col-span-2">
            <InputLabel htmlFor="is_featured" value="Category *" />

            <Select
              id="category"
              name="category"
              options={categories?.map(
                (category) => category.id + "-" + category.name
              )}
              className="mt-1 block w-full"
              onChange={onChange}
            />

            <InputError
              // message={errors?.name}
              className="mt-2 capitalize"
            />
          </div>

          <div className="col-span-2">
            <InputLabel htmlFor="is_featured" value="Featured? *" />

            <Select
              id="is_featured"
              name="is_featured"
              options={["yes", "no"]}
              className="mt-1 block w-full"
              onChange={onChange}
            />

            <InputError
              // message={errors?.name}
              className="mt-2 capitalize"
            />
          </div>

          <div className="col-span-2">
            <InputLabel htmlFor="discount" value="Discount(MWK)" />

            <TextInput
              id="discount"
              name="discount"
              type="number"
              step="0.01"
              className="mt-1 block w-full"
              value={data.discount}
              onChange={onChange}
            />

            <InputError
              // message={errors?.discount}
              className="mt-2 capitalize"
            />
          </div>
        </div>

        <div className="col-span-4">
          <InputLabel htmlFor="description" value="Product Description" />

          <TextArea
            id="description"
            name="description"
            className="mt-1 block w-full"
            value={data.description}
            onChange={onChange}
          />

          <InputError
            // message={errors?.description}
            className="mt-2 capitalize"
          />
        </div>
      </div>
    </Modal>
  );
};
