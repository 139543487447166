import { useSelector } from "react-redux";
import { orders as ordersURL} from "../../Data/apiRoutes";
import MainLayout from "../../Layouts/MainLayout";
import { useEffect, useState } from "react";
import CustomerOrders from "./Customer";
import SellerOrders from "./Seller";
import { useParams } from "react-router";
import axios from "axios";

export default function OrderShow() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [order, setOrder] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrder = async () => {
    try {
      const response = await axios.get(`${ordersURL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrder(response.data)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchOrder();
    setIsLoading(false);
  }, [user, token]);

  return (
    <MainLayout>
      {user?.role === "customer" ? (
        <CustomerOrders order={order} isLoading={isLoading} fetchOrder={fetchOrder} />
      ) : user?.role === "seller" ? (
        <SellerOrders order={order} />
      ) : (
        <></>
      )}
    </MainLayout>
  );
}
