export const dateFormat = (dd) => {
  const date = new Date(dd);
  return `${
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  }/${
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  }/${date.getFullYear()}`;
};

export const timeFormat = (dd) => {
  const date = new Date(dd);
  return `${date.getHours()}:${date.getMinutes()}`;
};

export const padZeros = (number, length) => {
  return number?.toString().padStart(length, "0");
};

export const numberFormat = (number) => {
  return number?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const isString = (value) =>
    typeof value === "string" || value instanceof String;