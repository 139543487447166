import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { lazy, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga";
import Home from "./Pages/Home";
import Onboarding from "./Pages/Onboarding";
import NavBar from "./Widgets/NavBar";
import CreateSession from "./Pages/CreateSession";
import Shops from "./Pages/Shops";
import ShowShop from "./Pages/ShowShop";
import TinyFAB from "./Widgets/TinyFAB";
import Messenger from "./Widgets/Messenger";
import Categories from "./Pages/Categories";
import Profile from "./Pages/Profile";
import Products from "./Pages/Products";
import ProductShow from "./Pages/ProductShow";
import Checkout from "./Pages/Checkout";
import About from "./Pages/About";
import Messages from "./Pages/Messages";
import ChatWindow from "./Pages/Messages/Show";
import Pricing from "./Pages/Pricing";
import EmailVerification from "./Pages/EmailVerification";
import Subscriptions from "./Pages/Subscriptions";
import Orders from "./Pages/Orders";
import Search from "./Pages/Search";
import BuyNow from "./Pages/BuyNow";
import Subscribe from "./Pages/Subscribe";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import NotFound from "./Pages/NotFound";
import HorizontalLinearStepper from "./Widgets/Steppers";
import OrderShow from "./Pages/OrderShow";
import StatusOrder from "./Pages/OrderShow/Customer/StatusOrder";

export default function App() {
  const isAuth = Boolean(useSelector((state) => state.token));
  const user = useSelector((state) => state.user);
  const isMessenger = Boolean(useSelector((state) => state.isMessenger));
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);
  const isAuthPage = useMemo(
    () =>
      currentLocation === "/login" ||
      currentLocation === "/register" ||
      currentLocation === "/forgot-password",
    [currentLocation]
  );
  useEffect(() => {
    setCurrentLocation(location.pathname);

    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
      category: "Users",
      action: `page_visit`,
      label: `${location.pathname}`,
    });
  }, [location]);

  return (
    <>
      {!isAuthPage && <NavBar />}
      <Routes>
        <Route
          path="/"
          element={
            user?.role === "pending" ? (
              <Onboarding />
            ) : !!user && !user?.email_verified_at ? (
              <EmailVerification />
            ) : (
              <Home />
            )
          }
        />
        <Route path="/shops" element={<Shops />} />
        <Route path="/shops/:id" element={<ShowShop />} />

        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductShow />} />

        <Route path="/categories" element={<Categories />} />

        <Route path="/checkout" element={<Checkout />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />

        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/search" element={<Search />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/steppin" element={<HorizontalLinearStepper />} />

        <Route
          path="/buy-now"
          element={isAuth ? <BuyNow /> : <Navigate to="/login" />}
        />

        {isAuth && (
          <>
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/:id" element={<OrderShow />} />
            <Route path="/orders/:method/success" element={<StatusOrder />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/messages/:id" element={<ChatWindow />} />
          </>
        )}

        <Route
          path="/login"
          element={!isAuth ? <Login /> : <Navigate to={"/"} />}
        />
        <Route
          path="/register"
          element={!isAuth ? <Register /> : <Navigate to={"/"} />}
        />
        <Route path="/session" element={<Navigate to={"/"} />} />
        <Route
          path="/session/:token"
          element={!isAuth ? <CreateSession /> : <Navigate to={"/"} />}
        />
        <Route path="/email-verification" element={<EmailVerification />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      {!isAuthPage &&
        user?.role !== "seller" &&
        user?.role !== "pending" &&
        user?.role !== "admin" &&
        !currentLocation.startsWith("/messages") && <TinyFAB />}

      {isMessenger && <Messenger />}

      <ToastContainer />
    </>
  );
}
