import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  const reset = () => setValues(initialValues);

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setValues((state) => ({ ...state, [e.target.name]: e.target.files[0] }));
      return;
    }
    setValues((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const change = (name, value) => {
    setValues((state) => ({ ...state, [name]: value }));
  };

  return { values, handleChange, reset, change };
};
