import AppLogo from "../Components/AppLogo";

export default function AuthLayout({ children, title = "" }) {
  return (
    <>
      <div className="sm:py-12 min-h-screen min-w-screen flex items-center justify-center">
        <div className="bg-white dark:bg-gray-800 sm:min-w-[50vw] md:min-w-[40vw] lg:min-w-[30vw] sm:w-fit w-full overflow-hidden shadow-sm sm:rounded-lg">
          <div className={`p-6 text-gray-900 dark:text-gray-100`}>
            <div className="flex justify-center my-5">
              <div className="flex flex-col items-center text-gray-500 dark:text-gray-300">
                <AppLogo className="h-30 w-auto fill-current text-gray-500" />
                {/* <FlashyLogo /> */}
                <div className="text-xl font-bold my-3">{title}</div>
              </div>
            </div>

            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  );
}
