import AppLogo from "./AppLogo";

export default function Loader() {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-6">
        <AppLogo />
        <svg className="loader" viewBox="25 25 50 50">
          <circle className="loader" r="20" cy="50" cx="50"></circle>
        </svg>
    </div>
  );
}
