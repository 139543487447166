import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

export default function Dropdown({ title = "Title Text", options = null }) {
  return (
    <div className="w-56 text-right z-20">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center px-4 py-2 text-sm font-medium text-black dark:text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {title}
            <ChevronDownIcon
              className="ml-2 -mr-1 h-5 w-5 text-gray-800 dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-20 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-gray-100 dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {options &&
                options.map((option) => (
                  <Menu.Item key={option.text}>
                    {({ active }) => {
                      if (option.onClick) {
                        return (
                          <button
                            onClick={option.onClick}
                            className={`${
                              active
                                ? "bg-gray-200 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                : "text-gray-600 dark:text-gray-300"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {option.text}
                          </button>
                        );
                      } else {
                        return (
                          <Link
                            to={option.href}
                            className={`${
                              active
                                ? "bg-gray-200 text-gray-900 dark:bg-gray-600 dark:text-gray-200"
                                : "text-gray-600 dark:text-gray-300"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {option.text}
                          </Link>
                        );
                      }
                    }}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
