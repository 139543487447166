import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Container from "../../Components/Container";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import InputError from "../../Components/InputError";
import PrimaryButton from "../../Components/PrimaryButton";
import { RiVisaLine } from "react-icons/ri";
import { IoIosArrowRoundForward } from "react-icons/io";
import MpambaIcon from "../../Assets/JSX/MpambaIcon";
import AirtelIcon from "../../Assets/JSX/AirtelIcon";
import {
  orders as orderURL,
  payments as paymentsURL,
} from "../../Data/apiRoutes";
import { clearCart, setTempData } from "../../Context/reducer";
import Spin from "../../Assets/JSX/Spin";
import { toast } from "react-toastify";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import OrderSuccess from "../OrderSuccess";
import TextArea from "../../Components/TextArea";
import axios from "axios";

export default () => {
  const isAuth = Boolean(useSelector((state) => state.token));
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [subTotal, setSubTotal] = useState(0);
  const [delivery, setDelivery] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [count, setCount] = useState(0);
  const [success, setSuccess] = useState(false);

  const [orderRes, setOrderRes] = useState(null);
  const [orderItemsRes, setOrderItemsRes] = useState(null);
  const [paymentRes, setPaymentRes] = useState(null);
  const [msisdnRes, setMsisdnRes] = useState(null);

  const [processing, setProcessing] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [data, setData] = useState({
    name: user?.name,
    phone_number: user?.phone_number,
    location: user?.location,
    email: user?.email,
    card_name: "",
    card_number: "",
    card_expiry: "",
    card_cvv: "",
    notes: "",
  });

  const [errors, setErrors] = useState({});

  const canPay = useMemo(() => {
    if (paymentMethod === "visa") {
      return true;
    }

    if (paymentMethod === "airtel") {
      if (
        data?.payment_number?.startsWith("2659", 0) &&
        data?.payment_number.length === 12
      ) {
        return true;
      }
    }

    if (paymentMethod === "mpamba") {
      if (
        data?.payment_number?.startsWith("2658", 0) &&
        data?.payment_number.length === 12
      ) {
        return true;
      }
    }

    return false;
  }, [data, paymentMethod]);

  const handleOnChange = (e) => {
    if (e.target.name === "card_cvv" && isNaN(e.target.value)) return;

    if (e.target.name === "card_number") {
      if (isNaN(e.target.value.replace(/\s/g, ""))) return;
      else {
        let value = e.target.value;

        // Remove any existing spaces and limit the input to 16 characters
        value = value.replace(/\s/g, "").substring(0, 16);

        // Add a space every 4 characters
        value = value.replace(/(.{4})/g, "$1 ");

        if (value.replace(/\s/g, "").length === 16)
          value = value.substring(0, 19);

        setData({
          ...data,
          [e.target.name]: value,
        });
        return;
      }
    }
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const payAction = async () => {
    setProcessing(true);
    toast.info("Order is being processed, do not go away from this page!");
    const order = {
      user_id: user.id,
      subtotal: subTotal - discount + delivery,
      recipient_name: data.name,
      recipient_number: data.phone_number,
      recipient_email: data.email,
      recipient_location: data.location,
      notes: data.notes,
      email: user.email,
    };
    const order_items = [];
    for (const item in cart) {
      if (Object.hasOwnProperty.call(cart, item)) {
        const element = cart[item];
        order_items.push({
          product_id: element.product_id,
          quantity: element.quantity,
          unit_price: element.unit_price,
          discount: element.discount,
          description: `${element.variant_id}|${element.color} - ${element.size}|${element.name}`,
          product_name: `${element.name}${
            !!element.color ? `, ${element.color} - ${element.size}` : ""
          }`,
          picture: element.picture,
        });
      }
    }

    if (paymentMethod === "airtel") {
      const response = await fetch(orderURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order,
          order_items,
          msisdn: data.payment_number,
          payment_method: 'airtel'
        }),
      });
      const output = await response.json();
      console.log(output);
      if (output.success) {
        dispatch(clearCart());
        toast.success("Order has been placed successfully.");
        setSuccess(true);
        setOrderRes(output.order);
        setOrderItemsRes(output.order_items);
        setPaymentRes(output.payment);
        setMsisdnRes(output.msisdn);
      } else {
        toast.error(output.message);
      }
    }

    if (paymentMethod === "visa") {
      let names = user.name.split(" ");
      let first_name, last_name;
      if (names.length > 1) {
        first_name = names[0];
        last_name = names[names.length - 1];
      } else {
        first_name = names[0];
        last_name = first_name;
      }

      const response = await fetch(orderURL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order,
          order_items,
          email: user.email,
          first_name,
          last_name,
          payment_method: 'paychangu'
        }),
      });

      const output = await response.json();

      if (output.success) {
        dispatch(setTempData(
          {
            order: output.order,
            order_items: output.order_items,
            email:output.email,
            first_name,
            last_name,
            payment:output.payment
          }
        ));

        dispatch(clearCart());
        if(output.payment_response.status == "success") {
          toast.success("Order has been placed successfully. You will be redirected to the payment page.");
          window.location.replace(output.payment_response.data.checkout_url);
        }

      } else {
        toast.error(output.message);
      }

      // const response = await axios.post(
      //   `${paymentsURL}/init/paychangu`,
      //   {
      //     amount: order.subtotal,
      //     email: user.email,
      //     first_name,
      //     last_name,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // if(response.data.status == "success") {
      //   window.location.replace(response.data.data.checkout_url);
      // }
    }
    setProcessing(false);
  };

  useEffect(() => {
    if (!isAuth) navigate("/login");
    if (user?.role !== "customer") navigate("/");
  }, []);

  useEffect(() => {
    var sum = 0;
    var discount = 0;
    var count = 0;
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      sum += element.unit_price * element.quantity;
      discount += element.discount * element.quantity;
      count += element.quantity;
    }
    setSubTotal(sum);
    setDiscount(discount);
    setCount(count);
  }, [cart]);

  return (
    <MainLayout>
      {success ? (
        <OrderSuccess
          order={orderRes}
          orderItems={orderItemsRes}
          payment={paymentRes}
          msisdn={msisdnRes}
        />
      ) : cart.length > 0 ? (
        <Container>
          <div className="md:grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <div className="text-2xl font-bold">Checkout</div>
              <div className="text-xs text-gray-500">
                A checkout is a counter where you pay for the things you are
                buying.
              </div>

              <div className="mt-6 font-bold">Recipient Information</div>
              <div className="md:grid grid-cols-2">
                <div className="my-3 md:mx-2">
                  <InputLabel htmlFor="name" value="Full Name" />

                  <TextInput
                    id="name"
                    type="text"
                    name="name"
                    value={data.name}
                    className="mt-1 block w-full"
                    autoComplete="name"
                    onChange={handleOnChange}
                  />

                  <InputError message={errors?.name} className="mt-2" />
                </div>
                <div className="my-3 md:mx-2">
                  <InputLabel htmlFor="phone_number" value="Phone Number" />

                  <TextInput
                    id="phone_number"
                    type="text"
                    name="phone_number"
                    value={data.phone_number}
                    className="mt-1 block w-full"
                    autoComplete="phone_number"
                    onChange={handleOnChange}
                  />

                  <InputError message={errors?.phone_number} className="mt-2" />
                </div>

                <div className="my-3 md:mx-2">
                  <InputLabel htmlFor="email" value="Email Address" />

                  <TextInput
                    id="email"
                    type="text"
                    name="email"
                    value={data.email}
                    className="mt-1 block w-full"
                    autoComplete="email"
                    onChange={handleOnChange}
                  />

                  <InputError message={errors?.email} className="mt-2" />
                </div>

                <div className="my-3 md:mx-2">
                  <InputLabel htmlFor="location" value="Location/Address" />

                  <TextInput
                    id="location"
                    type="text"
                    name="location"
                    value={data.location}
                    className="mt-1 block w-full"
                    autoComplete="location"
                    onChange={handleOnChange}
                  />

                  <InputError message={errors?.location} className="mt-2" />
                </div>

                <div className="col-span-2 md:mx-2">
                  <InputLabel htmlFor="notes" value="Notes/Description" />
                  <TextArea
                    id="notes"
                    name="notes"
                    value={data.notes}
                    className="mt-1 block w-full"
                    onChange={handleOnChange}
                  />
                </div>
              </div>

              <div className="mt-6 font-bold">Payment Method</div>
              <div className="flex gap-6">
                {/* FIXME: remove hidden style */}
                <button
                  className={`text-3xl border py-2 px-4 rounded-md ${
                    paymentMethod === "visa"
                      ? "bg-blue-700"
                      : "hover:bg-gray-300 dark:hover:bg-gray-700"
                  }`}
                  onClick={() => setPaymentMethod("visa")}
                >
                  <RiVisaLine />
                </button>
                {/* FIXME: remove hidden style */}
                <button
                  className={` flex items-center border px-4 rounded-md hidden ${
                    paymentMethod === "mpamba"
                      ? "bg-blue-700"
                      : "hover:bg-gray-300 dark:hover:bg-gray-700"
                  }`}
                  onClick={() => setPaymentMethod("mpamba")}
                >
                  <MpambaIcon className="w-10 h-10" />
                  <span className="ms-2 hidden md:flex"> Mpamba</span>
                </button>

                <button
                  className={`text-3xl border py-2 px-4 rounded-md ${
                    paymentMethod === "airtel"
                      ? "bg-blue-700"
                      : "hover:bg-gray-300 dark:hover:bg-gray-700"
                  }`}
                  onClick={() => setPaymentMethod("airtel")}
                >
                  <AirtelIcon height={30} width={100} fill={"red"} />
                </button>
              </div>

              {/* PAYMENT INPUTS */}
              <div className={!!paymentMethod ? "border-y my-2 py-2" : ""}>
                {/* {paymentMethod === "visa" && (
                  <>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-3">
                      <InputLabel htmlFor="card_name" value="Name on Card" />

                      <TextInput
                        id="card_name"
                        type="text"
                        name="card_name"
                        value={data.card_name}
                        className="mt-1 block w-full"
                        onChange={handleOnChange}
                      />

                      <InputError
                        message={errors?.card_name}
                        className="mt-2"
                      />
                    </div>
                    <div className="col-span-3">
                      <InputLabel htmlFor="card_number" value="Card Number" />

                      <TextInput
                        id="card_number"
                        type="text"
                        name="card_number"
                        value={data.card_number}
                        className="mt-1 block w-full"
                        onChange={handleOnChange}
                      />

                      <InputError
                        message={errors?.card_number}
                        className="mt-2"
                      />
                    </div>
                    <div className="col-span-2">
                      <InputLabel
                        htmlFor="card_expiry"
                        value="Card Expiry Date"
                      />

                      <TextInput
                        id="card_expiry"
                        type="month"
                        name="card_expiry"
                        value={data.card_expiry}
                        className="mt-1 block w-full"
                        onChange={handleOnChange}
                      />

                      <InputError
                        message={errors?.card_expiry}
                        className="mt-2"
                      />
                    </div>

                    <div className="">
                      <InputLabel htmlFor="card_cvv" value="Card CVV" />

                      <TextInput
                        id="card_cvv"
                        type="text"
                        name="card_cvv"
                        value={data.card_cvv}
                        className="mt-1 block w-full"
                        onChange={handleOnChange}
                        maxlength="4"
                      />

                      <InputError message={errors?.card_cvv} className="mt-2" />
                    </div>
                  </div>
                  </>
                )} */}

                {paymentMethod !== "visa" && !!paymentMethod && (
                  <div>
                    <InputLabel htmlFor="payment_number" value="Phone Number" />

                    <TextInput
                      id="payment_number"
                      type="text"
                      name="payment_number"
                      value={data.payment_number}
                      className="mt-1 block w-full"
                      onChange={handleOnChange}
                      placeholder={
                        paymentMethod === "mpamba" ? "26588..." : "26599..."
                      }
                    />

                    <InputError
                      message={errors?.payment_number}
                      className="mt-2"
                    />
                  </div>
                )}
              </div>
              {/* END PAYMENT INPUTS */}
            </div>
            <div className="space-y-8 relative mt-5 md:mt-0">
              <div className="text-3xl text-center">{count} Items</div>

              <div className="space-y-4">
                <div className="grid grid-cols-2 border-b border-b-gray-300 dark:border-b-gray-700">
                  <div className="text-gray-400">Subtotal</div>
                  <div className="text-right">
                    {subTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="grid grid-cols-2 border-b border-b-gray-300 dark:border-b-gray-700">
                  <div className="text-gray-400">Discount</div>
                  <div className="text-right">
                    {discount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="grid grid-cols-2 border-b border-b-gray-300 dark:border-b-gray-700">
                  <div className="text-gray-400">Delivery Service</div>
                  <div className="text-right">
                    {delivery.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 items-baseline font-bold">
                <div className="text-gray-400 text-lg ">Total</div>
                <div className="text-right flex items-baseline justify-end gap-1 col-span-2">
                  <span>MWK </span>
                  <span className="text-2xl">
                    {(subTotal - discount + delivery).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
              </div>

              <div className="md:absolute bottom-0 left-0 right-0">
                <div className="text-red-500 text-sm text-center my-2">
                  Be sure to confirm with PIN on your phone when using mobile
                  payment!
                </div>
                <PrimaryButton
                  className="w-full flex justify-center gap-2"
                  disabled={!canPay || processing}
                  onClick={payAction}
                >
                  {!processing ? (
                    <>
                      PROCEED <IoIosArrowRoundForward className="text-xl" />
                    </>
                  ) : (
                    <Spin />
                  )}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <Container className="h-[58vh] flex flex-col gap-3 justify-center items-center">
          <div className="my-3">
            <MdOutlineRemoveShoppingCart className="text-6xl" />
          </div>
          <div>Cart is Empty</div>
          <Link to={"/products"}>
            <PrimaryButton>Continue Shopping</PrimaryButton>
          </Link>
        </Container>
      )}
    </MainLayout>
  );
};
