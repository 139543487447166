import { useDispatch, useSelector } from "react-redux";
import Container from "../../Components/Container";
import { IoMdCheckmarkCircleOutline, IoIosArrowForward } from "react-icons/io";
import { RxCrossCircled } from "react-icons/rx";
import { useEffect, useState } from "react";
import {payments as paymentUrl} from "../../Data/apiRoutes"
import axios from "axios"
import { setTempData } from "../../Context/reducer";

export default ({
  order,
  orderItems,
  payment,
  msisdn = null,
  email = null,
  first_name,
  last_name,
  status = "success",
}) => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [sum, setSum] = useState(0);
  const [discount, setDiscount] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    for (let index = 0; index < orderItems.length; index++) {
      const element = orderItems[index];
      setSum((s) => s + element.quantity * element.unit_price);
      setDiscount((s) => s + element.quantity * element.discount);
    }
  }, [orderItems]);

  const retryPayment = async () => {
    try {
      const response = await axios.post(
        `${paymentUrl}/init/paychangu`,
        {
          amount: order.subtotal,
          email,
          first_name,
          last_name,
          order
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(response.data.status == "success") {
        dispatch(setTempData(
          {
            order,
            order_items: orderItems,
            email,
            first_name,
            last_name,
            payment: {...payment, trans_id: response.data.tx_ref}
          }
        ));

        window.location.replace(response.data.data.checkout_url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container size="max-w-4xl" padding="">
      {status === "success" ? (
        <div className="flex flex-col items-center bg-blue-500 dark:bg-blue-600 text-white justify-center p-10">
          <IoMdCheckmarkCircleOutline className="text-7xl" />
          <div className="text-2xl font-bold mt-2">
            Your order has been placed successful
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center bg-red-500 dark:bg-red-600 text-white justify-center p-10">
          <RxCrossCircled className="text-7xl" />
          <div className="text-2xl font-bold mt-2">
            Your payment was not successful!
          </div>
          <button className="flex items-center mt-6 font-bold text-white" onClick={retryPayment}>
            Retry Payment <IoIosArrowForward className="font-bold" />
          </button>
        </div>
      )}

      <div className="p-5">
        <div className="my-5 text-center">
          <div>
            Please verify the information below! <br />
            You will receive the receipt through email when the payment has been
            completed!
          </div>
        </div>

        <div className="border-b-2 pb-4 mb-4">
          <div className="md:grid grid-cols-2 gap-6">
            <div>
              <div className="text-lg font-black">RECIPIENT:</div>
              <div className="grid grid-cols-5">
                <div className="font-bold">Name:</div>
                <div className="col-span-4 text-end">
                  {order?.recipient_name}
                </div>
                <div className="font-bold">Email:</div>
                <div className="col-span-4 text-end">
                  {order?.recipient_email}
                </div>
                <div className="font-bold">Contact:</div>
                <div className="col-span-4 text-end">
                  {order?.recipient_number}
                </div>
                <div className="font-bold">Location:</div>
                <div className="col-span-4 text-end">
                  {order?.recipient_location}
                </div>
              </div>
              <div className="text-red-500 font-bold my-2 cursor-pointer hover:underline hidden">
                Click here to update your address.
              </div>
            </div>
            <div>
              <div className="text-lg font-black">PAYMENT DETAILS:</div>
              <div className="grid grid-cols-5">
                <div className="font-bold">Method:</div>
                <div className="col-span-4 text-end capitalize">
                  {payment?.method}
                </div>
                <div className="font-bold">Reference:</div>
                <div className="col-span-4 text-end">{payment?.trans_id}</div>
                <div className="font-bold">Date:</div>
                <div className="col-span-4 text-end capitalize">
                  {payment?.createdAt?.split("T")[0]}
                </div>
                {msisdn && (
                  <>
                    <div className="font-bold">Number:</div>
                    <div className="col-span-4 text-end">{msisdn}</div>
                  </>
                )}
                {email && (
                  <>
                    <div className="font-bold">Email:</div>
                    <div className="col-span-4 text-end">{email}</div>
                  </>
                )}
              </div>
              {order?.notes && (
                <>
                  <div className="text-lg font-black mt-2">NOTES:</div>
                  <div className="text-sm">{order?.notes}</div>
                </>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="font-bold">ITEMS:</div>
          <div className="space-y-1">
            {!!orderItems &&
              orderItems?.length > 0 &&
              orderItems.map((orderItem) => (
                <div className="md:grid grid-cols-6 border-b border-gray-200 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 p-2">
                  <div className="col-span-4">
                    <div className="font-bold">{orderItem.product_name}</div>
                    <div className="text-sm">
                      {orderItem?.quantity}x @ K
                      {orderItem?.unit_price
                        .toFixed(2)
                        .toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                    </div>
                  </div>
                  <div className="col-span-2 md:text-right font-bold">
                    K{" "}
                    {(orderItem?.unit_price * orderItem?.quantity)
                      .toFixed(2)
                      .toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="md:grid grid-cols-2 mt-3">
          <div></div>
          <div className="grid grid-cols-2">
            <div className="font-bold">SubTotal</div>
            <div className="text-right">
              K{" "}
              {sum.toFixed(2).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
            <div className="font-bold">Discount</div>
            <div className="text-right">
              K{" "}
              {discount.toFixed(2).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
            <div className="font-bold">Delivery Fee</div>
            <div className="text-right">K 0.00</div>
            <div className="font-bold">TOTAL:</div>
            <div className="text-right font-bold">
              K{" "}
              {(sum - discount).toFixed(2).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
