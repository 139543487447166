import { useState } from "react";
import Modal from "../Components/Modal";
import PrimaryButton from "../Components/PrimaryButton";
import Select from "../Components/Select";
import TextInput from "../Components/TextInput";
import { numberFormat } from "../Functions/functions.js";
import { setBuyNow } from "../Context/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default ({ show, close, product }) => {
  const [variant, setVariant] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const proceed = () => {
    const prod = {
      product_id: product.id,
      variant_id: null,
      name: product.name,
      unit_price: product.price,
      discount: product.discount,
      quantity,
      picture: product.picture,
      color: null,
      size: null,
    };
    if (product?.ProductVariants?.length > 0) {
      prod.variant_id = variant.split(" - ")[0];
      prod.color = variant.split(" - ")[1].split("; ")[0];
      prod.size = variant.split(" - ")[1].split("; ")[1];
    }

    dispatch(setBuyNow(prod));
    navigate("/buy-now");
  };

  return (
    <Modal
      show={show}
      close={close}
      title={`Select Variant`}
      customOk={
        <PrimaryButton
          disabled={product?.ProductVariants?.length > 0 ? !variant : false}
          onClick={proceed}
        >
          Buy Now
        </PrimaryButton>
      }
      maxWidth="max-w-lg"
    >
      <div className="flex flex-col md:flex-row gap-4 text-gray-900 dark:text-gray-100">
        <div className="flex justify-center">
          <img
            src={
              product?.picture
                ? `${process.env.REACT_APP_BACKEND_URL}/${product?.picture
                    ?.replace('"', "")
                    .replace('"', "")}`
                : "/img/shop-icon.png"
            }
            alt="product-1"
            className="object-cover w-[130px] h-[130px]"
          />
        </div>
        <div className="flex flex-col grow">
          <div className="font-bold">{product?.name}</div>
          <div className="text-sm">MWK {numberFormat(product?.price)}</div>

          {product?.ProductVariants?.length > 0 && (
            <div className="mt-auto">
              <div className="grid grid-cols-5 items-center">
                <div>Var:</div>
                <div className="col-span-4">
                  <Select
                    placeholder="Select Variant"
                    options={product?.ProductVariants.map(
                      (variant) =>
                        `${variant.id} - ${variant.color}; ${variant.size}`
                    )}
                    onChange={(e) => setVariant(e.target.value)}
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 items-center">
                <div>QTY:</div>
                <div className="col-span-4">
                  <TextInput
                    placeholder={"Quantity"}
                    className="w-full"
                    type={"number"}
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    min={1}
                    max={
                      !!variant
                        ? product?.ProductVariants.filter(
                            (vari) => vari.id == variant.split(" - ")[0]
                          )[0].quantity
                        : 1
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
