import MainLayout from "../../Layouts/MainLayout";
import Admin from "./Admin";

export default () => {
  return (
    <MainLayout>
      <Admin />
    </MainLayout>
  );
};
