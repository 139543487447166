import Container from "../Components/Container";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { useState } from "react";
import { QontoConnector, QontoStepIcon } from "../Components/StepperComponents";

export default ({
  steps,
  activeStep,
  setActiveStep,
  isStepOptional,
  children,
  nextable = true,
  finish = () => {},
}) => {
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length) {
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <>
      <div className="mt-10 md:mt-0"></div>
      <Container padding="py-6 md:px-6">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <div className="text-gray-950 dark:text-gray-50 text-xs md:text-sm lg:text-base">
                    {label}
                  </div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <div className="px-6 md:px-0">{children}</div>

        <div className="px-6 md:px-0">
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button
              onClick={() => {
                if (activeStep === steps.length) {
                  finish();
                } else {
                  handleNext();
                }
              }}
              disabled={!nextable}
            >
              {activeStep === steps.length - 1
                ? "Finish"
                : activeStep === steps.length
                ? "Submit"
                : "Next"}
            </Button>
          </Box>
        </div>
      </Container>
    </>
  );
};
