import Container from "../../../Components/Container";
import { TbMessage2Question } from "react-icons/tb";

export default () => {
  const words = ["Top Quality", "Original", "The Best"];
  const images = [
    "young-african-american-woman-shopping-with-colorful-packs-blue-background-attractive-female-model.jpg",
    "shopping-bg.jpg",
    "young-african-american-woman-shopping-with-colorful-packs-black-wall.jpg",
    "cyber-monday-shopping-sales.jpg",
  ];
  return (
    <Container transparent padding="" xpadding="">
      <div className="lg:grid grid-cols-2 gap-2 space-y-2 lg:space-y-0">
        {[1, 2].map((i) => (
          <div key={"slide1"} className="relative h-[30dvh] lg:h-[40dvh]">
            <div className="text-left absolute p-6 top-1/2 -translate-y-1/2 z-10">
              <div className="text-gray-600 dark:text-gray-400 text-sm uppercase mb-0">
                {words[Math.round(Math.random() * 10) % 3]}
              </div>
              <div className="text-lg lg:text-xl mt-0 mb-3">
                Advertisements Here!!
              </div>
              <div className="text-xs text-gray-600 dark:text-gray-400">
                from
              </div>
              <div className="text-xl lg:text-2xl font-extrabold">
                K1,750.00
              </div>
              <button className="border border-gray-950 dark:border-gray-50 hover:bg-gray-950 hover:text-gray-50 dark:hover:bg-gray-50 dark:hover:text-gray-950 py-1 lg:py-2 px-2 lg:px-4 transition flex gap-1 items-center">
                <span>Inquire Now</span>
                <TbMessage2Question />
              </button>
            </div>

            <div className="bg-gradient-to-r from-gray-100 dark:from-gray-950 to-transparent w-full h-full absolute top-0 left-0 opacity-75 z-[5]"></div>

            <div className="h-[30dvh] lg:h-[40dvh] w-full absolute">
              <img
                // src="/img/cyber-monday-shopping-sales.jpg"
                src={`/img/${images[Math.round(Math.random() * 10) % 4]}`}
                className="h-[30dvh] lg:h-[40dvh] w-full object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
