import MainLayout from "../../Layouts/MainLayout";
import { useState, useRef, useMemo } from "react";
import StepperWidget from "../../Widgets/StepperWidget";
import { useDispatch, useSelector } from "react-redux";
import { GiShop } from "react-icons/gi";
import { FaUserCircle } from "react-icons/fa";
import InputLabel from "../../Components/InputLabel";
import InputError from "../../Components/InputError";
import TextInput from "../../Components/TextInput";
import TextArea from "../../Components/TextArea";
import { padZeros } from "../../Functions/functions";
import { userUpdateURL } from "../../Data/apiRoutes";
import { setUser } from "../../Context/reducer";
import { toast } from "react-toastify";

const steps = [
  "Account Type",
  "Basic Details",
  "Address",
  "Personal Biography",
  "Profile Picture",
];

export default () => {
  const [activeStep, setActiveStep] = useState(0);

  const isStepOptional = (step) => {
    return [3].includes(step);
  };

  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [userType, setUserType] = useState(user?.role);
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState({
    name: user?.name,
    email: user?.email,
    phone_number: user?.phone_number,
    location: user?.location,
    description: user?.description,
    photo: null,
    district: "",
    postal_address: "",
  });
  // eslint-disable-next-line
  const [errors, setErrors] = useState({
    name: "",
    phone_number: "",
    location: "",
  });

  const isNextable = useMemo(() => {
    switch (activeStep) {
      case 0:
        if (!userType && userType === "pending") return false;
        break;
      case 1:
        if (!data?.name || !data?.phone_number || !data?.dob) {
          setErrors({
            ...errors,
            name: !data.name ? "Please enter a name!" : "",
            phone_number: !data.phone_number
              ? "Please enter a phone number!"
              : "",
            dob: !data.dob ? "Please enter a Date of Birth!" : "",
          });
          return false;
        }
        break;
      case 2:
        if (!data?.district || !data?.location || !data?.postal_address) {
          setErrors({
            ...errors,
            district: !data.district ? "Please enter a district!" : "",
            location: !data.location
              ? "Please enter an area or location in the district!"
              : "",
            postal_address: !data.postal_address
              ? "Please enter a Postal Address!"
              : "",
          });
          return false;
        }
        break;
      case 3:
        if (!data?.description) {
          return false;
        }
        break;
      case 4:
        if (!data?.photo) {
          setErrors({
            ...errors,
            photo: !data.photo
              ? "Please select a picture to identify you by!"
              : "",
          });
          return false;
        }
        break;
      default:
        break;
    }
    setErrors({});
    return true;
  }, [data, activeStep, userType, selectedImage]);

  const finish = async () => {
    try {
      let reqData = new FormData();

      for (let key in data) {
        reqData.append(key, data[key]);
      }
      reqData.append("role", userType);

      const response = await fetch(userUpdateURL(user.id), {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: reqData,
      });

      const resData = await response.json();
      dispatch(setUser(resData.user));
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  const selectImage = () => {
    imageRef.current.click();
  };

  const handleOnChange = (e) =>
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setData({ ...data, [e.target.name]: e.target.files[0] });
      if (e.target.name === "photo") {
        setSelectedImage(e.target.files[0]);
      }
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  let dd = new Date();
  dd.setFullYear(new Date().getFullYear() - 10);
  const maxDate = `${dd.getFullYear()}-${padZeros(dd.getMonth(), 2)}-${padZeros(
    dd.getDate(),
    2
  )}`;

  return (
    <MainLayout>
      <StepperWidget
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        isStepOptional={isStepOptional}
        nextable={isNextable}
        finish={finish}
      >
        {activeStep === steps.length ? (
          <>
            <div className="text-xl text-center my-6 font-bold">
              Review Your Profile
            </div>
            <div className="space-y-2 sm:grid grid-cols-4 gap-2">
              <div className="col-span-4 sm:flex gap-4">
                <div className="flex justify-center">
                  <div className="w-[150px] h-[150px] sm:w-[250px] sm:h-[250px] relative border border-gray-500 rounded">
                    <img
                      className="object-cover h-full w-full"
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : "/img/user-icon.png"
                      }
                    />
                  </div>
                </div>
                <div className="sm:grid grid-cols-3 gap-2 w-full">
                  <div>
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      Display Name:
                    </div>{" "}
                    <div className="text-lg">
                      {data?.name} - (
                      <span className="capitalize">{userType}</span>)
                    </div>
                  </div>
                  <div>
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      Email:
                    </div>{" "}
                    <div className="text-lg">{data?.email}</div>
                  </div>
                  <div>
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      Phone Number:
                    </div>{" "}
                    <div className="text-lg">{data?.phone_number}</div>
                  </div>
                  <div>
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      Date of Birth:
                    </div>{" "}
                    <div className="text-lg">{data?.dob}</div>
                  </div>
                  <div>
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      District:
                    </div>{" "}
                    <div className="text-lg">{data?.district}</div>
                  </div>
                  <div>
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      Area/Location:
                    </div>{" "}
                    <div className="text-lg">{data?.location}</div>
                  </div>
                  <div className="col-span-3">
                    <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                      Postal Address:
                    </div>{" "}
                    <div className="text-lg">{data?.postal_address}</div>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <div className="font-bold text-sm text-gray-600 dark:text-gray-400">
                  Description:
                </div>{" "}
                <div className="text-lg">{data?.description}</div>
              </div>
            </div>
          </>
        ) : activeStep === 0 ? (
          <>
            <div className="text-xl text-center my-6 font-bold">
              Which one are you?
            </div>
            <div className="grid grid-cols-2 gap-2 sm:gap-6">
              <div className="flex justify-center">
                <div
                  className={`min-w-[150px] md:min-w-[200px] ${
                    userType === "seller"
                      ? "bg-gray-200 dark:bg-gray-600"
                      : "bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                  } p-6 px-5 sm:px-10 text-center relative rounded-lg flex flex-col items-center justify-center cursor-pointer`}
                  onClick={() =>
                    setUserType((s) => (s === "seller" ? "" : "seller"))
                  }
                >
                  <input
                    type="checkbox"
                    className="absolute top-2 right-2"
                    checked={userType === "seller"}
                    onChange={() =>
                      setUserType((s) => (s === "seller" ? "" : "seller"))
                    }
                  />

                  <GiShop
                    className={`text-6xl ${
                      userType === "seller"
                        ? "fill-blue-500 dark:fill-blue-300"
                        : "fill-blue-600 dark:fill-blue-400"
                    }`}
                  />
                  <div className="font-bold mt-5">Seller/Supplier</div>
                </div>
              </div>
              <div className="flex justify-center">
                <div
                  className={`min-w-[150px] md:min-w-[200px] ${
                    userType === "customer"
                      ? "bg-gray-200 dark:bg-gray-600"
                      : "bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                  } p-6 px-5 sm:px-10 text-center relative rounded-lg flex flex-col items-center justify-center cursor-pointer`}
                  onClick={() =>
                    setUserType((s) => (s === "customer" ? "" : "customer"))
                  }
                >
                  <input
                    type="checkbox"
                    className="absolute top-2 right-2"
                    checked={userType === "customer"}
                    onChange={() =>
                      setUserType((s) => (s === "customer" ? "" : "customer"))
                    }
                  />
                  <FaUserCircle
                    className={`text-6xl ${
                      userType === "customer"
                        ? "fill-blue-500 dark:fill-blue-300"
                        : "fill-blue-600 dark:fill-blue-400"
                    }`}
                  />
                  <div className="font-bold mt-6">Customer</div>
                </div>
              </div>
            </div>
          </>
        ) : activeStep === 1 ? (
          <>
            <div className="text-xl text-center my-6 font-bold">
              Basic Information
            </div>
            <div className="sm:grid grid-cols-2 gap-3">
              <div className="my-3">
                <InputLabel htmlFor="name" value="Full Name" />

                <TextInput
                  id="name"
                  type="text"
                  name="name"
                  value={data.name}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors?.name} className="mt-2" />
              </div>

              <div className="my-3">
                <InputLabel htmlFor="phone_number" value="Phone Number" />

                <TextInput
                  id="phone_number"
                  type="text"
                  name="phone_number"
                  value={data.phone_number}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors?.phone_number} className="mt-2" />
              </div>
              <div className="my-3">
                <InputLabel htmlFor="email" value="Email Address" />

                <TextInput
                  id="email"
                  type="text"
                  name="email"
                  value={data.email}
                  className="mt-1 block w-full"
                  disabled
                />

                <InputError message={errors?.email} className="mt-2" />
              </div>

              <div className="my-3">
                <InputLabel htmlFor="dob" value="Date of Birth" />

                <TextInput
                  id="dob"
                  type="date"
                  name="dob"
                  value={data.dob}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                  max={maxDate}
                />

                <InputError message={errors?.dob} className="mt-2" />
              </div>
            </div>
          </>
        ) : activeStep === 2 ? (
          <>
            <div className="text-xl text-center my-6 font-bold">
              Residential Address
            </div>
            <div className="sm:grid grid-cols-2 gap-3">
              <div className="my-3">
                <InputLabel htmlFor="district" value="District" />

                <TextInput
                  id="district"
                  type="text"
                  name="district"
                  value={data.district}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors?.district} className="mt-2" />
              </div>
              <div className="my-3">
                <InputLabel htmlFor="location" value="Area/Location" />

                <TextInput
                  id="location"
                  type="text"
                  name="location"
                  value={data.location}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors?.location} className="mt-2" />
              </div>

              <div className="my-3 col-span-2">
                <InputLabel htmlFor="postal_address" value="Postal Address" />

                <TextArea
                  id="postal_address"
                  type="text"
                  name="postal_address"
                  value={data.postal_address}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors?.postal_address} className="mt-2" />
              </div>
            </div>
          </>
        ) : activeStep === 3 ? (
          <>
            <div className="text-xl text-center my-6 font-bold">
              How can you describe yourself?
            </div>
            <div className="">
              <div className="my-3">
                <InputLabel htmlFor="description" value="Description" />

                <TextArea
                  id="description"
                  type="text"
                  name="description"
                  value={data.description}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                  rows={4}
                />

                <InputError message={errors?.description} className="mt-2" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-xl text-center my-6 font-bold">
              Profile Picture
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="w-[250px] h-[250px] relative border border-gray-500 rounded">
                <TextInput
                  id="photo"
                  name="photo"
                  type="file"
                  accept="image/jpeg,image/jpg,image/png"
                  className="hidden"
                  ref={imageRef}
                  onChange={onChange}
                />
                <img
                  className="object-cover h-full w-full"
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : "/img/user-icon.png"
                  }
                />
                <div
                  onClick={selectImage}
                  className="cursor-pointer absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 flex justify-center items-center"
                >
                  <div>Select Image</div>
                </div>

                <div
                  className="cursor-pointer absolute z-40 bottom-1 right-1 bg-blue-500 p-1 rounded"
                  onClick={selectImage}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="fill-white"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path>
                    <path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path>
                  </svg>
                </div>
              </div>
              <InputError message={errors?.photo} className="mt-2" />
            </div>
          </>
        )}
      </StepperWidget>
    </MainLayout>
  );
};
