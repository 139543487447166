import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cart from "../Components/Cart";
import { FaShoppingCart } from "react-icons/fa";

export default function TinyFAB() {
  const user = useSelector((s) => s.user);
  const cart = useSelector((s) => s.cart);
  const [isCart, setIsCart] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const openCart = () => {
    setIsCart((state) => !state);
  };

  useEffect(() => {
    setCartCount(cart.length);
  }, [cart]);
  return (
    <>
      <Cart show={isCart} close={() => setIsCart((s) => !s)} />
      {user?.role !== "admin" && user?.role !== "seller" && (
        <button
          className="fixed bottom-4 right-4 z-50 bg-blue-500 text-white rounded-full p-4 shadow-lg flex items-center justify-center"
          onClick={openCart}
        >
          <FaShoppingCart className="text-2xl" />

          {cartCount > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white px-1 py-0 rounded-full text-xs">
              {cartCount}
            </span>
          )}
        </button>
      )}
    </>
  );
}
