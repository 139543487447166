import { useDispatch, useSelector } from "react-redux";
import NewProduct from "../../Products/Modal/NewProduct";
import NewProductVariant from "../../Products/Modal/NewProductVariant";
import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import { useEffect, useState } from "react";
import Datatable from "../../../Components/Datatable";
import { Link } from "react-router-dom";
import TrashIcon from "../../../Assets/JSX/TrashIcon";
import EditIcon from "../../../Assets/JSX/EditIcon";
import {
  products as allProducts,
  products as newProductRoute,
  variants as newVariant,
  categories as categoryUrl,
} from "../../../Data/apiRoutes";
import { numberFormat } from "../../../Functions/functions";
import UpdateProduct from "../../Products/Modal/UpdateProduct";
import { useProductUpdate } from "../../../Hooks/useProductUpdate";
import UpdateProductVariants from "../../Products/Modal/UpdateProductVariants";

export default function SellerShowShop({ shop }) {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState([]);
  const [newProduct, setNewProduct] = useState({});
  const [newProductVariants, setNewProductVariants] = useState([]);
  const [processing, setProcessing] = useState(false);

  const [isNewProductModal, setIsNewproductModal] = useState(false);
  const [isNewProductVariantModal, setIsNewproductVariantModal] =
    useState(false);

  const refresh = async () => {
    const response = await fetch(`${allProducts}/${shop?.id}/shop`);
    const data = await response.json();
    setProducts(data.products);
  };

  const saveProduct = async () => {
    setProcessing((s) => !s);
    try {
      let productData = new FormData();
      productData.append("name", newProduct.name);
      productData.append("description", newProduct.description);
      productData.append("price", newProduct.price);
      productData.append("photo", newProduct.picture);
      productData.append("featured", newProduct.is_featured === "yes");
      productData.append("discount", newProduct.discount);
      productData.append("category_id", newProduct.category.split("-")[0]);
      productData.append("user_id", user.id);
      productData.append("shop_id", shop.id);
      const productRes = await fetch(newProductRoute, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: productData,
      });

      if (productRes.ok) {
        const product = (await productRes.json()).newProduct;
        let failedVariants = [];

        newProductVariants.forEach(async (variant) => {
          let variantData = new FormData();
          variantData.append("photo", variant.picture);
          variantData.append("color", variant.color);
          variantData.append("size", variant.size);
          variantData.append("quantity", variant.quantity);
          variantData.append("product_id", product.id);
          const variantRes = await fetch(newVariant, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: variantData,
          });
          if (!variantRes.ok) {
            failedVariants.push(variant);
          }
        });

        console.log("failedVariants", failedVariants);
        setIsNewproductVariantModal((s) => !s);
        setNewProductVariants(null);
        setNewProduct(null);
        refresh();
      }
    } catch (err) {
      console.log(err);
    }

    setProcessing((s) => !s);
  };

  useEffect(() => {
    refresh();
    (async () => {
      const response = await fetch(categoryUrl);
      const data = await response.json();
      setCategories(data.categories);
    })();
  }, [shop]);

  // FOR PRODUCT UPDATING
  const [product, setProduct] = useState({});
  const [productVariants, setProductVariants] = useState([]);
  const [removedVariants, setRemovedVariants] = useState([]);
  const [productModal, setIsProductModal] = useState(false);
  const [productVariantModal, setIsProductVariantModal] = useState(false);

  const {
    shops,
    saveProduct: saveUpdateProduct,
    update: updateAction,
    back: backAction,
  } = useProductUpdate({
    user,
    token,
    product,
    productVariants,
    removedVariants,
    setProduct,
    setIsProductModal,
    setIsProductVariantModal,
    setProductVariants,
    setRemovedVariants,
    refresh,
    setProcessing,
  });
  //

  return (
    <Container>
      {productModal && (
        <UpdateProduct
          show={productModal}
          product={product}
          setProduct={setProduct}
          categories={categories}
          close={() => setIsProductModal((s) => !s)}
          setIsProductVariantModal={setIsProductVariantModal}
          shops={shops}
        />
      )}

      {productVariantModal && (
        <UpdateProductVariants
          show={productVariantModal}
          productVariants={productVariants}
          setProductVariants={setProductVariants}
          back={backAction}
          close={() => setIsProductVariantModal((s) => !s)}
          save={saveUpdateProduct}
          processing={processing}
          setRemovedVariants={setRemovedVariants}
        />
      )}

      <NewProduct
        show={isNewProductModal}
        setProduct={setNewProduct}
        setIsNewproductVariantModal={setIsNewproductVariantModal}
        close={() => setIsNewproductModal((s) => !s)}
        categories={categories}
      />

      <NewProductVariant
        show={isNewProductVariantModal}
        productVariants={newProductVariants}
        setProductVariants={setNewProductVariants}
        close={() => setIsNewproductVariantModal((s) => !s)}
        save={saveProduct}
        processing={processing}
        back={() => {
          setIsNewproductVariantModal((s) => !s);
          setIsNewproductModal((s) => !s);
        }}
      />

      <div className="mb-3 flex justify-between">
        <div>{shop?.name}</div>
        <PrimaryButton onClick={() => setIsNewproductModal((s) => !s)}>
          New Product
        </PrimaryButton>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-6">
        <div></div>
        <div className="font-bold col-start-2 col-end-4">Product Name</div>
        <div className="font-bold col-start-4 col-end-6 text-end">Price</div>
        <div className="font-bold text-center">View/Update</div>
      </div>
      {!!products ? (
        products.map((row, index) => (
          <div
            key={`shop-${row.id}`}
            className="grid grid-cols-4 md:grid-cols-6 gap-2 p-2 items-center border border-gray-300 dark:border-gray-700 mb-2 rounded shadow hover:bg-gray-900 transition-all"
          >
            <Link to={`/products/${row.id}`}>
              <img
                src={
                  row?.picture
                    ? `${
                        process.env.REACT_APP_BACKEND_URL
                      }/${row?.picture?.replace(/"/g, "")}`
                    : "/img/image_icon1.png"
                }
                width={60}
                height={60}
                className="bg-white rounded object-cover"
              />
            </Link>
            <Link to={`/products/${row.id}`} className="col-start-2 col-end-4">
              {row?.name}
            </Link>
            <div className="col-start-4 col-end-6 hidden md:inline-block text-end">
              {numberFormat(row?.price)}
            </div>
            <div className="">
              <div className="flex justify-center gap-5">
                <button onClick={() => updateAction(row)}>
                  <EditIcon className="fill-gray-900 dark:fill-gray-200" />
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>Loading...</>
      )}
    </Container>
  );
}
