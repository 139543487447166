import Container from "../../../Components/Container";
import { Tab } from "@headlessui/react";
import { useState } from "react";
import ProductsPanel from "../../../Widgets/ProductsPanel";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default ({ products, categories }) => {
  return (
    <Container size="w-full" ypadding="pt-5" xpadding="" transparent padding="">
      {!!products && products.length > 0 ? (
        <Tab.Group>
          <Tab.List className="border-b border-gray-200 dark:border-gray-700 text-gray-500 dark:text-gray-400">
            {["All", "Recents"].map((tab) => (
              <Tab
                className={({ selected }) =>
                  classNames(
                    "outline-none transition-all p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300",
                    selected
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : ""
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <ProductsPanel categories={categories} products={products} />
            </Tab.Panel>
            <Tab.Panel>
              <ProductsPanel
                categories={categories}
                products={products.filter(
                  (product) =>
                    new Date(product.createdAt).getTime() >=
                    Date.now() - 7 * 24 * 60 * 60 * 1000
                )}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      ) : (
        <Container className="flex h-[50vh] justify-center items-center">
          <div className="text-xl font-bold">
            There are no products at the moment.
          </div>
        </Container>
      )}
    </Container>
  );
};
