import { useDispatch, useSelector } from "react-redux";
import AppLogo from "../../../Components/AppLogo";
import Modal from "../../../Components/Modal";
import PrimaryButton from "../../../Components/PrimaryButton";
import TextInput from "../../../Components/TextInput";
import { useEffect, useState } from "react";
import { verifyPhone, users as userUrl } from "../../../Data/apiRoutes";
import Spin from "../../../Assets/JSX/Spin";
import { setUser } from "../../../Context/reducer";
import { toast } from "react-toastify";

export default ({ resendOtp, show, close, setIsProcessing }) => {
  const dispatch = useDispatch();
  const user = useSelector((s) => s.user);
  const token = useSelector((s) => s.token);
  const [otp, setOtp] = useState("");
  const [processing, setProcessing] = useState(false);
  const [counter, setCounter] = useState(0);

  const onChangeHandler = (e) => {
    if (isNaN(e.target.value.replace(/\s/g, ""))) return;
    else {
      let value = e.target.value;

      // Remove any existing spaces
      value = value.replace(/\s/g, "");

      var len = value.length;

      // Add a space after every character
      value = value.split("").join("   ");

      if (len <= 5) {
        setOtp(value);
      }
    }
  };

  const verifyPhoneNumber = async (e) => {
    e.preventDefault();
    setProcessing(true);
    try {
      const response = await fetch(verifyPhone, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone_number: user?.phone_number,
          code: otp.replace(/\s/g, ""),
        }),
      });
      const data = await response.json();
      console.log(data);
      if (data.status === true) {
        const res = await fetch(`${userUrl}/${user.id}`);
        const userData = await res.json();
        dispatch(setUser(userData));
        toast.success("Phone number verified successfully.");
        close();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      setProcessing(false);
    }
    setProcessing(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(timer); // Clear the interval when counter reaches 0
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [counter]);
  return (
    <Modal
      show={show}
      close={close}
      title="Verify Phone Number"
      customOk={<></>}
      maxWidth="max-w-xl"
    >
      <form
        className="text-gray-900 dark:text-gray-50"
        onSubmit={verifyPhoneNumber}
      >
        <div className="flex justify-center items-center">
          <div className="bg-gray-300 dark:bg-gray-700 p-4 rounded-full">
            <AppLogo className="w-10 h-10 object-cover" />
          </div>
        </div>

        <div className="text-2xl font-bold text-center my-3">
          Phone Number Verification
        </div>

        <div className="text-center">
          Enter the 5 digit code that was sent to your phone number:
          <div className="text-blue-500">{user?.phone_number}</div>
        </div>

        <TextInput
          className="w-full my-3 text-center"
          value={otp}
          onChange={onChangeHandler}
        />

        <PrimaryButton
          className="w-full flex justify-center"
          disabled={otp.replace(/\s/g, "").length < 5 || processing}
          onClick={verifyPhoneNumber}
        >
          {processing && <Spin />}
          Verify
        </PrimaryButton>

        <div className="flex justify-center pt-5">
          {processing && <Spin />}
          {!processing && counter === 0 && (
            <div
              className="text-sm text-gray-600 dark:text-gray-400 hover:underline cursor-pointer"
              onClick={() => {
                setCounter(60);
                resendOtp();
              }}
            >
              Resend OTP?
            </div>
          )}
          {counter > 0 && (
            <div className="text-sm text-gray-600 dark:text-gray-400">
              Wait {counter} seconds to send OTP.
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};
