import { useSelector } from "react-redux";
import Container from "../../Components/Container";
import MainLayout from "../../Layouts/MainLayout";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import InputError from "../../Components/InputError";
import { useState, useEffect } from "react";
import { MdEdit, MdSave, MdOutlineVerifiedUser  } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { sendSMSOTP, users as userRoute } from "../../Data/apiRoutes";
import VerifyPhone from "./Modals/VerifyPhone";
import { useForm } from "../../Hooks/useForm";
import { toast } from "react-toastify";
import axios from "axios";
import { dateFormat } from "../../Functions/functions";


export default function Profile() {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  const { values: data, handleChange, reset } = useForm({});
  const [errors, setErrors] = useState({});

  const [isProcessing, setIsProcessing] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const navigate = useNavigate();

  const verifyContact = async () => {
    setVerifyModal((s) => !s);
  };

  const sendOtp = async () => {
    try {
      await fetch(sendSMSOTP, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone_number: user?.phone_number }),
      });
      toast.success("OTP has been sent");
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = async () => {
    setIsProcessing(true)
    try {
      setErrors({});
      if(data?.new_password?.length < 8 || data?.confirm_password?.length < 8) {
        setIsProcessing(false)
        return setErrors({new_password: "Password length should be at least 8 characters!"})
      }

      if(data?.new_password?.length !== data?.confirm_password?.length) {
        setIsProcessing(false)
        return setErrors({new_password: "Passwords do not match!"})
      }

      if(data?.current_password && data?.new_password && data?.confirm_password) {
        const response = await axios.patch(`${userRoute}/${user.id}/password`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });
  
        toast.success(response.data.message);
        reset();
      } else {
        toast.error("Password fields should not be empty!");
        setErrors({current_password: "Password fields should not be empty!"})
      }
      
    } catch (error) {
      console.log(error)
      if(error?.response?.status == 400) {
        toast.error(error?.response?.data?.error);
        setErrors({current_password: error?.response?.data?.error})
      }
    }
    setIsProcessing(false)
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, []);

  return (
    <MainLayout isProcessing={isProcessing}>
      <VerifyPhone
        resendOtp={sendOtp}
        show={verifyModal}
        close={() => setVerifyModal((s) => !s)}
        setIsProcessing={setIsProcessing}
      />
      <Container>
        <div className="font-bold text-xl mb-6">My Profile</div>
        <div className="md:flex gap-6 border dark:border-gray-700 rounded p-2 mb-6">
          <div className="flex-shrink-0 w-20 h-20 relative">
            <img
              src={
                user?.picture
                  ? `${process.env.REACT_APP_BACKEND_URL}/${user?.picture
                      ?.replace('"', "")
                      .replace('"', "")}`
                  : "/img/user-icon.png"
              }
              className="object-cover w-full h-full rounded-full shadow border"
              alt="user"
            />
          </div>

          <div className="grow">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">{user?.name}</div>
              <button className="border p-2 md:px-3 md:py-1 flex gap-2 items-center rounded hover:bg-slate-700 text-sm text-gray-400">
                <MdEdit /> <span className="hidden md:block">Edit</span>
              </button>
            </div>
            <div className="text-sm text-gray-600 dark:text-gray-400">
              {`${user?.location}, ${user?.district}`}
            </div>
            <div className="text-sm text-gray-500 text-justify">
              {user?.description}
            </div>
          </div>
        </div>

        <div className="border dark:border-gray-700 rounded p-2 mb-6">
          <div className="flex justify-between items-center mb-3">
            <div className="font-bold">Personal Information</div>

            <div className="ms-auto">
              <button className="border p-2 md:px-3 md:py-1 flex gap-2 items-center rounded hover:bg-slate-700 text-sm text-gray-400">
                <MdEdit /> <span className="hidden md:block">Edit</span>
              </button>
            </div>
          </div>

          <div className="md:grid grid-cols-2 gap-6 space-y-4 md:space-y-0">
            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Full Name
              </div>
              <div>{user?.name}</div>
            </div>

            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Email Address
              </div>
              <div>{user?.email}</div>
            </div>

            <div>
              <div className="flex justify-between">
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Phone Number
              </div>
                {!user?.phone_verified_at && <button onClick={verifyContact} className="border p-2 md:px-3 md:py-1 flex gap-2 items-center rounded hover:bg-slate-700 text-sm text-gray-400">
                  <MdOutlineVerifiedUser /> <span className="hidden md:block">Verify</span>
                </button>}
              </div>
              <div>{user?.phone_number}</div>
            </div>

            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Date of Birth
              </div>
              <div>{dateFormat(user?.dob)}</div>
            </div>

            <div className="col-span-2">
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Bio
              </div>
              <div>{user?.description}</div>
            </div>
          </div>
        </div>

        <div className="border dark:border-gray-700 rounded p-2 mb-6">
          <div className="flex justify-between items-center mb-3">
            <div className="font-bold">Address</div>

            <div className="ms-auto">
              <button className="border p-2 md:px-3 md:py-1 flex gap-2 items-center rounded hover:bg-slate-700 text-sm text-gray-400">
                <MdEdit /> <span className="hidden md:block">Edit</span>
              </button>
            </div>
          </div>

          <div className="md:grid grid-cols-2 gap-6 space-y-4 md:space-y-0">
            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                City/Location
              </div>
              <div>{user?.location}</div>
            </div>

            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                District
              </div>
              <div>{user?.district}</div>
            </div>

            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Country
              </div>
              <div>Malawi</div>
            </div>

            <div>
              <div className="text-sm font-light text-gray-600 dark:text-gray-400">
                Postal Address
              </div>
              <div>{user?.postal_address}</div>
            </div>
          </div>
        </div>

        <div className="border-2 dark:border-gray-600 rounded p-2 mb-6">
          <div className="flex justify-between items-center mb-3">
            <div className="font-bold">Password Change</div>

            <div className="ms-auto">
              <button
                onClick={changePassword}
                className="border p-2 md:px-3 md:py-1 flex gap-2 items-center rounded hover:bg-slate-700 text-sm text-gray-400"
              >
                <MdSave /> <span className="hidden md:block">Save</span>
              </button>
            </div>
          </div>

          <div className="mb-3">
            <InputLabel htmlFor="current_password" value="Current Password" />

            <TextInput
              id="current_password"
              type="password"
              name="current_password"
              value={data?.current_password}
              className="mt-1 block w-full"
              autoComplete="current_password"
              onChange={handleChange}
            />

            <InputError message={errors?.current_password} className="mt-2" />
          </div>

          <div className="mb-3">
            <InputLabel htmlFor="new_password" value="New Password" />

            <TextInput
              id="new_password"
              type="password"
              name="new_password"
              value={data?.new_password}
              className="mt-1 block w-full"
              autoComplete="new_password"
              onChange={handleChange}
            />

            <InputError message={errors?.new_password} className="mt-2" />
          </div>

          <div className="mb-3">
            <InputLabel htmlFor="confirm_password" value="Confirm Password" />

            <TextInput
              id="confirm_password"
              type="password"
              name="confirm_password"
              value={data?.confirm_password}
              className="mt-1 block w-full"
              autoComplete="confirm_password"
              onChange={handleChange}
            />

            <InputError message={errors?.confirm_password} className="mt-2" />
          </div>
        </div>
      </Container>
    </MainLayout>
  );
}
