import { useDispatch, useSelector } from "react-redux";
import NewShop from "./NewShop";
import Container from "../../../Components/Container";
import PrimaryButton from "../../../Components/PrimaryButton";
import { useEffect, useState } from "react";
import Datatable from "../../../Components/Datatable";
import { Link } from "react-router-dom";
import TrashIcon from "../../../Assets/JSX/TrashIcon";
import EditIcon from "../../../Assets/JSX/EditIcon";
import { shops as allShops } from "../../../Data/apiRoutes";
import { setShops } from "../../../Context/reducer";
import { dateFormat } from "../../../Functions/functions.js";
import UpdateShop from "./UpdateShop";

export default function SellerShop() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const shops = useSelector((state) => state.shops);
  const [showAddShop, setShowAddShop] = useState(false);
  const [showUpdateShop, setShowUpdateShop] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);

  const update = (clickedShop) => {
    setSelectedShop(clickedShop);
    setShowUpdateShop((s) => !s);
  };

  const refresh = async () => {
    // TODO:
    const response = await fetch(`${allShops}/mine`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user_id: user.id }),
    });
    const data = await response.json();
    dispatch(setShops(data.shops));
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Container>
      <NewShop
        user={user}
        token={token}
        show={showAddShop}
        close={() => setShowAddShop((s) => !s)}
        refresh={refresh}
      />

      <UpdateShop
        user={user}
        token={token}
        show={showUpdateShop}
        shop={selectedShop}
        close={() => setShowUpdateShop((s) => !s)}
        refresh={refresh}
      />

      <div className="mb-3 flex justify-between">
        <div></div>
        <PrimaryButton onClick={() => setShowAddShop((s) => !s)}>
          Add Shop
        </PrimaryButton>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-6">
        <div></div>
        <div className="font-bold col-start-2 col-end-4">Shop Name</div>
        <div className="font-bold col-start-4 col-end-6 hidden md:inline-block">
          Created At
        </div>
        <div className="font-bold text-center">View/Update</div>
      </div>

      {!!shops ? (
        shops.map((row) => (
          <div
            key={`shop-${row.id}`}
            className="grid grid-cols-4 md:grid-cols-6 gap-2 p-2 items-center border border-gray-300 dark:border-gray-700 mb-2 rounded shadow hover:bg-gray-900 transition-all"
          >
            <Link to={`/shops/${row.id}`}>
              <img
                src={
                  row?.picture
                    ? `${
                        process.env.REACT_APP_BACKEND_URL
                      }/${row?.picture?.replace(/"/g, "")}`
                    : "/img/image_icon1.png"
                }
                width={60}
                height={60}
                className="bg-white rounded object-cover"
              />
            </Link>
            <Link to={`/shops/${row.id}`} className="col-start-2 col-end-4">
              {row.name}
            </Link>
            <div className="col-start-4 col-end-6 hidden md:inline-block">
              {dateFormat(row.createdAt)}
            </div>
            <div className="">
              <div className="flex justify-center gap-5">
                <button onClick={() => update(row)}>
                  <EditIcon className="fill-gray-900 dark:fill-gray-200" />
                </button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <>Loading...</>
      )}
    </Container>
  );
}
