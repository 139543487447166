import Footer from "../Components/Footer";
import Loader from "../Components/Loader";

export default function MainLayout({
  header,
  top = "top-[6vh]",
  children,
  noFooter = false,
  isProcessing = false,
}) {
  return (
    <div
      className={`min-h-[94vh] ${top} flex flex-col bg-gray-100 dark:bg-gray-900 relative z-10`}
    >
      {isProcessing ? (
        <Loader />
      ) : (
        <>
          {header && (
            <header className="bg-white dark:bg-gray-800 shadow">
              <div className="max-w-7xl mx-auto sm:py-6 px-4 sm:px-6 lg:px-8">
                {header}
              </div>
            </header>
          )}

          <main className="text-gray-950 dark:text-gray-50">{children}</main>
          {!noFooter && <Footer />}
        </>
      )}
    </div>
  );
}
