import { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import {
  categories as categoryURL,
  shopProducts,
} from "../../../Data/apiRoutes";
import ProductsPanel from "../../../Widgets/ProductsPanel";

export default ({ shop }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      setIsProcessing(true);
      const response = await fetch(categoryURL);
      const data = await response.json();
      setCategories(data.categories);
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  const fetchProducts = async () => {
    try {
      setIsProcessing(true);
      const response = await fetch(shopProducts(shop?.id));
      const data = await response.json();
      setProducts(data.products);
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, [shop]);
  return (
    <Container size="w-full" ypadding="pt-5" xpadding="" transparent padding="">
      <div className="mt-10 text-center text-lg font-black mb-3">
        {shop?.name}
      </div>
      <div>
        <ProductsPanel
          categories={categories}
          products={products}
          isProcessing={isProcessing}
        />
      </div>
    </Container>
  );
};
