export default function Container({
  children,
  size = "max-w-7xl",
  padding = "p-6",
  ypadding = "py-4 sm:py-12",
  xpadding = "sm:px-6 lg:px-8",
  className = "",
  outterClassName = "",
  transparent = false,
}) {
  return (
    <div className={`${ypadding} ${outterClassName}`}>
      <div className={`${size} mx-auto ${xpadding}`}>
        <div
          className={`${
            transparent ? "" : "bg-white dark:bg-gray-800 shadow-sm"
          } overflow-hidden sm:rounded-lg`}
        >
          <div
            className={`${padding} ${className} text-gray-900 dark:text-gray-100`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
