import Container from "../../Components/Container";
import MainLayout from "../../Layouts/MainLayout";
import SecondaryButton from "../../Components/SecondaryButton";
import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { subscriptionTypes } from "../../Data/apiRoutes";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscriptionTypes,
  setselectedSubscriptionType,
} from "../../Context/reducer";
import SubscriptionPackage from "../../Widgets/SubscriptionPackage";
import { useNavigate } from "react-router-dom";
import { useSubscriptionTypes } from "../../Hooks/useSubscriptions";

export default () => {
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const packages = useSelector((state) => state.subscriptionTypes);

  const selectSub = (item) => {
    dispatch(
      setselectedSubscriptionType({
        ...item,
        sub_type: switchEnabled ? "annually" : "monthly",
      })
    );
    navigate("/subscribe");
  };

  useState(() => {
    (async () => {
      try {
        const res = await fetch(subscriptionTypes);
        const data = await res.json();
        dispatch(setSubscriptionTypes(data));
        console.table(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <MainLayout>
      <Container transparent className="text-center space-y-4 mt-10">
        <div className="text-3xl font-black">
          Ready to start with Malonda265?
        </div>
        <div className="text-lg text-gray-500">
          Choose a package that suits you.
        </div>
      </Container>
      <div className="flex justify-center items-center space-x-4">
        <div
          className={`${
            !switchEnabled
              ? "text-blue-600"
              : "text-gray-700 dark:text-gray-300"
          } font-bold text-lg transition`}
        >
          Monthly
        </div>
        <Switch
          checked={switchEnabled}
          onChange={setSwitchEnabled}
          className={`${
            switchEnabled ? "bg-blue-600" : "bg-gray-300 dark:bg-gray-600"
          } relative inline-flex h-6 w-11 items-center rounded-full shadow`}
        >
          <span
            className={`${
              switchEnabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
        <div
          className={`${
            switchEnabled ? "text-blue-600" : "text-gray-700 dark:text-gray-300"
          } font-bold text-lg transition`}
        >
          Annually
        </div>
      </div>
      <div className="sm:grid grid-cols-4 content-stretch justify-stretch items-stretch">
        {!!packages &&
          packages.map((item) => (
            <SubscriptionPackage
              key={item.id}
              item={item}
              switchEnabled={switchEnabled}
              onClickAction={() => {
                selectSub(item);
              }}
            />
          ))}
      </div>
    </MainLayout>
  );
};
