import AuthLayout from "../../Layouts/AuthLayout";
import MainLayout from "../../Layouts/MainLayout";
import TextInput from "../../Components/TextInput";
import InputLabel from "../../Components/InputLabel";
import InputError from "../../Components/InputError";
import Select from "../../Components/Select";
import PrimaryButton from "../../Components/PrimaryButton";
import SecondaryButton from "../../Components/SecondaryButton";
import GoogleIcon from "../../Assets/JSX/GoogleIcon";
import FacebookIcon from "../../Assets/JSX/FacebookIcon";
import GithubIcon from "../../Assets/JSX/GithubIcon";
import { useState } from "react";
import { Link } from "react-router-dom";
import { oauth } from "../../Functions/oauth";
import Checkbox from "../../Components/Checkbox";
import { useDispatch } from "react-redux";
import { setLogin } from "../../Context/reducer";
import Spin from "../../Assets/JSX/Spin";
import { register } from "../../Data/apiRoutes";

export default function Register() {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    username: "",
    name: "  ",
    phone_number: "265",
    password: "",
    c_password: "",
    email: "",
    role: "pending",
  });
  const [errors, setErrors] = useState({});
  const [terms, setTerms] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleOnChange = (e) =>
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  const thirdPartyAuth = async () => {
    setProcessing((s) => !s);
    oauth("google");
  };

  const submit = async (e) => {
    e.preventDefault();
    setProcessing((s) => !s);
    var errs = {};

    Object.keys(data).forEach((key) => {
      if (!!data[key]) {
      } else {
        errs = {
          ...errs,
          [key]: `This field is required!`,
        };
      }
    });

    if (terms === false) {
      errs = {
        ...errs,
        terms: `You need to accept terms and conditions!`,
      };
    }
    setErrors(errs);

    if (Object.keys(errs).length > 0) {
      setProcessing((s) => !s);
      return;
    }

    if (data.c_password !== data.password) {
      errs = {
        ...errs,
        password: `Passwords do not match!`,
        c_password: `Passwords do not match!`,
      };
      setErrors(errs);
    } else {
      try {
        // TODO: email, phone number, and username validation here!

        const body = { ...data };
        delete body.c_password;
        const response = await fetch(register, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(body),
        });
        const output = await response.json();

        if (response.status === 500) {
          setErrors(output);
          setProcessing((s) => !s);
          return;
        }

        dispatch(setLogin(output));
      } catch (error) {}
    }
    setProcessing((s) => !s);
  };

  return (
    <MainLayout top="">
      <AuthLayout title="Sign Up">
        <form onSubmit={submit}>
          <div className="sm:grid grid-cols-2 gap-3">
            <div className="my-2 sm:m-0">
              <InputLabel htmlFor="username" value="Username" />

              <TextInput
                id="username"
                type="text"
                name="username"
                value={data.username}
                className="mt-1 block w-full"
                onChange={handleOnChange}
              />

              <InputError message={errors.username} className="mt-2" />
            </div>

            <div className="my-2 sm:m-0 order-2 md:col-span-2">
              <InputLabel htmlFor="email" value="Email" />

              <TextInput
                id="email"
                type="email"
                name="email"
                value={data.email}
                className="mt-1 block w-full"
                onChange={handleOnChange}
              />

              <InputError message={errors?.email} className="mt-2" />
            </div>

            <div className="my-2 sm:m-0 col-span-3 sm:col-span-1">
              <InputLabel htmlFor="phone_number" value="Phone Number" />

              <TextInput
                id="phone_number"
                type="text"
                name="phone_number"
                value={data.phone_number}
                className="mt-1 block w-full"
                onChange={handleOnChange}
              />

              <InputError message={errors.phone_number} className="mt-2" />
            </div>

            <div className="grid grid-cols-2 col-span-2 gap-2 order-last sm:gap-3">
              <div className="my-2 sm:m-0">
                <InputLabel htmlFor="password" value="Password" />

                <TextInput
                  id="password"
                  type="password"
                  name="password"
                  value={data.password}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors.password} className="mt-2" />
              </div>

              <div className="my-2 sm:m-0">
                <InputLabel htmlFor="c_password" value="Confirm Password" />

                <TextInput
                  id="c_password"
                  type="password"
                  name="c_password"
                  value={data.c_password}
                  className="mt-1 block w-full"
                  onChange={handleOnChange}
                />

                <InputError message={errors?.c_password} className="mt-2" />
              </div>
            </div>
          </div>

          <div className="block mt-4">
            <label className="flex items-center">
              <Checkbox
                name="terms"
                onChange={(e) => setTerms(e.target.checked)}
              />
              <span className="ml-2 text-gray-600 dark:text-gray-400">
                I agree with the{" "}
                <Link
                  className="text-blue-700 dark:text-blue-300 underline"
                  to={"/terms-and-conditions"}
                >
                  terms & conditions
                </Link>
              </span>
            </label>
            <InputError message={errors?.terms} className="mt-2 capitalize" />
          </div>

          <div className="flex items-center justify-end my-6">
            <Link
              to={"/login"}
              className="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800"
            >
              Log In instead?
            </Link>

            <PrimaryButton className="ml-4" disabled={processing}>
              {processing && <Spin />}
              Register
            </PrimaryButton>
          </div>
        </form>

        <div className="hidden">
          <div className="flex justify-between items-center my-6">
            <hr className="w-[40%]" />
            <div className="font-bold text-sm">OR</div>
            <hr className="w-[40%]" />
          </div>
          <div className="flex flex-col gap-3 justify-around mt-3">
            <SecondaryButton
              onClick={thirdPartyAuth}
              className="flex items-center justify-center gap-3"
              disabled={processing}
            >
              {processing && <Spin />}
              <GoogleIcon className="dark:fill-white fill-black" />
              <div>Sign up with Google</div>
            </SecondaryButton>
            {/* <PrimaryButton className="flex items-center justify-center gap-3">
            <FacebookIcon className="fill-white dark:fill-black" />
            <div>Sign in with Facebook</div>
          </PrimaryButton>
          <PrimaryButton className="flex items-center justify-center gap-3">
            <GithubIcon className="fill-white dark:fill-black" />
            <div>Sign in with Github</div>
          </PrimaryButton> */}
          </div>
        </div>
      </AuthLayout>
    </MainLayout>
  );
}
