import { useState, useEffect } from "react";
import {
  shops as allShops,
  products as productRoute,
  variants as variantRoute,
} from "../Data/apiRoutes";
import { toast } from "react-toastify";

export const useProductUpdate = ({
  user,
  token,
  product,
  productVariants,
  removedVariants,
  setProduct,
  setIsProductModal,
  setIsProductVariantModal,
  setProductVariants,
  setRemovedVariants,
  refresh,
  setProcessing,
}) => {
  const [shops, setShops] = useState([]);
  const fetchShops = async () => {
    const response = await fetch(`${allShops}/mine`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user_id: user.id }),
    });
    const data = await response.json();
    setShops(data.shops);
  };

  useEffect(() => {
    fetchShops();
  }, []);

  const update = (product) => {
    setProduct(product);
    setIsProductModal(true);
    setProductVariants(product?.ProductVariants);
  };

  const back = () => {
    setIsProductModal(true);
    setIsProductVariantModal(false);
  };

  const saveProduct = async () => {
    setProcessing((s) => !s);
    try {
      let productData = new FormData();
      productData.append("name", product.name);
      productData.append("description", product.description);
      productData.append("price", product.price);
      productData.append("photo", product.picture);
      productData.append("featured", product.is_featured === "yes");
      productData.append("discount", product.discount);
      productData.append("category_id", product.category.split("-")[0]);
      productData.append("shop_id", product.shop.split("-")[0]);
      productData.append("user_id", product.user_id);

      const productRes = await fetch(`${productRoute}/${product.id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: productData,
      });

      if (productRes.ok) {
        let failedVariants = [];

        productVariants.forEach(async (variant) => {
          let variantData = new FormData();
          variantData.append("photo", variant.picture);
          variantData.append("color", variant.color);
          variantData.append("size", variant.size);
          variantData.append("quantity", variant.quantity);
          variantData.append("product_id", product.id);

          if (variant?.id) {
            const variantRes = await fetch(`${variantRoute}/${variant?.id}`, {
              method: "PATCH",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: variantData,
            });
            
            if (!variantRes.ok) {
              failedVariants.push(variant);
            }
          } else {
            const variantRes = await fetch(variantRoute, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: variantData,
            });
            if (!variantRes.ok) {
              failedVariants.push(variant);
            }
          }
        });

        removedVariants.forEach(async (variant) => {
          if (variant?.id) {
            const variantRes = await fetch(`${variantRoute}/${variant?.id}`, {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (!variantRes.ok) {
              failedVariants.push(variant);
            }
          }
        });

        setIsProductVariantModal((s) => !s);
        setProductVariants([]);
        setProduct({});
        setRemovedVariants([]);
        refresh();
        toast.success("Product Successfully updated!");
      }
    } catch (err) {
      console.log(err);
      toast.error("Product failed to updated!");
    }

    setProcessing((s) => !s);
  };

  return { shops, saveProduct, update, back };
};
