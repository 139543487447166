import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { setLogin } from "../Context/reducer";
import { session } from "../Data/apiRoutes";

export default function CreateSession() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const data = await fetch(session, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => response.json());
      dispatch(
        setLogin({
          token,
          user: data.user,
        })
      );
      navigate("/");
    })();
  });

  return <div>Hellow</div>;
}
