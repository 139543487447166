import axios from "axios";
import { wishlist } from "../Data/apiRoutes";
import SecondaryButton from "./SecondaryButton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ToWishlistButton(props) {
  const token = useSelector((state) => state.token);

  const wishlistAction = async () => {
    try {
      const response = await axios.post(
        wishlist,
        {
          user_id: props.user.id,
          product_id: props.product.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 201) toast.success(response.data.message);
      else toast.warning(response.data.message);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };
  return (
    <SecondaryButton {...props} onClick={wishlistAction}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        className="text-white text-sm fill-white"
      >
        <path d="M20.205 4.791a5.938 5.938 0 0 0-4.209-1.754A5.906 5.906 0 0 0 12 4.595a5.904 5.904 0 0 0-3.996-1.558 5.942 5.942 0 0 0-4.213 1.758c-2.353 2.363-2.352 6.059.002 8.412L12 21.414l8.207-8.207c2.354-2.353 2.355-6.049-.002-8.416z"></path>
      </svg>
      {!props.hideText && <span className="ms-1">Add to Wishlist</span>}
    </SecondaryButton>
  );
}
